import React from "react";

export const CatalogApartmentIcon = () => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 4.55556L8 1L15 4.55556V13.4444L8 17L1 13.4444V4.55556Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const CatalogCommerceIcon = () => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 4.55556L8 1L15 4.55556V13.4444L8 17L1 13.4444V4.55556Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.49 10.155C10.49 10.647 10.31 11.07 9.95 11.424C9.59 11.772 9.071 11.988 8.393 12.072V13.08H7.493V12.099C7.049 12.075 6.626 11.997 6.224 11.865C5.822 11.733 5.498 11.571 5.252 11.379L5.747 10.281C5.975 10.449 6.242 10.59 6.548 10.704C6.854 10.818 7.169 10.89 7.493 10.92V9.417C7.067 9.315 6.707 9.201 6.413 9.075C6.119 8.949 5.873 8.766 5.675 8.526C5.477 8.28 5.378 7.956 5.378 7.554C5.378 7.056 5.558 6.63 5.918 6.276C6.284 5.916 6.809 5.697 7.493 5.619V4.62H8.393V5.601C9.131 5.661 9.74 5.844 10.22 6.15L9.77 7.257C9.32 6.999 8.861 6.843 8.393 6.789V8.319C8.813 8.415 9.167 8.526 9.455 8.652C9.749 8.772 9.995 8.955 10.193 9.201C10.391 9.441 10.49 9.759 10.49 10.155ZM6.827 7.482C6.827 7.632 6.884 7.755 6.998 7.851C7.112 7.947 7.277 8.028 7.493 8.094V6.816C7.265 6.87 7.097 6.957 6.989 7.077C6.881 7.191 6.827 7.326 6.827 7.482ZM8.393 10.884C8.609 10.836 8.771 10.758 8.879 10.65C8.987 10.542 9.041 10.413 9.041 10.263C9.041 10.113 8.984 9.99 8.87 9.894C8.762 9.798 8.603 9.714 8.393 9.642V10.884Z"
        fill="black"
      />
    </svg>
  );
};
export const CatalogFavoriteIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.0714 13.143L13.4146 18.7998C12.6335 19.5809 11.3672 19.5809 10.5862 18.7998L4.92931 13.143C2.97669 11.1903 2.97669 8.02451 4.92931 6.07189C6.88193 4.11927 10.0478 4.11927 12.0004 6.07189C13.953 4.11927 17.1188 4.11927 19.0714 6.07189C21.0241 8.02451 21.0241 11.1903 19.0714 13.143Z"
        stroke="#242328"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CatalogPlaceIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 10.625C11.3807 10.625 12.5 9.50571 12.5 8.125C12.5 6.74429 11.3807 5.625 10 5.625C8.61929 5.625 7.5 6.74429 7.5 8.125C7.5 9.50571 8.61929 10.625 10 10.625Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6668 8.077C16.6668 13.8462 10.0002 18.3334 10.0002 18.3334C10.0002 18.3334 3.3335 13.8462 3.3335 8.077C3.3335 6.3769 4.03587 4.74643 5.28612 3.54427C6.53636 2.34211 8.23205 1.66675 10.0002 1.66675C11.7683 1.66675 13.464 2.34211 14.7142 3.54427C15.9644 4.74643 16.6668 6.3769 16.6668 8.077V8.077Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const CatalogHouseIcon = () => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 4.55556L8 1L15 4.55556V13.4444L8 13.5L1 13.4444V4.55556Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CatalogPlotsIcon = () => {
  return (
    <svg
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4091 8.19531L17.5455 10.2963L9.27276 14.4984L1 10.2963L5.13638 8.19531"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 5.702L9.27276 1.49997L17.5455 5.702L9.27276 9.90404L1 5.702Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const ArrowButtonIcon = () => {
  return (
    <svg
        className="dialog-booking-icon"
        width="24"
        height="24"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
      <path
          d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05888 27.9411 0 18 0C8.05888 0 0 8.05888 0 18C0 27.9411 8.05888 36 18 36Z"
          fill="#F1623D"
      />
      <path
          d="M24.2066 16.6667L19.0578 11.4226C18.7383 11.0972 18.7383 10.5695 19.0578 10.2441C19.3773 9.91864 19.8954 9.91864 20.2149 10.2441L26.7604 16.9107C27.0799 17.2362 27.0799 17.7638 26.7604 18.0893L20.2149 24.7559C19.8954 25.0814 19.3773 25.0814 19.0578 24.7559C18.7383 24.4305 18.7383 23.9028 19.0578 23.5774L24.2066 18.3333H9.81818C9.36631 18.3333 9 17.9602 9 17.5C9 17.0398 9.36631 16.6667 9.81818 16.6667H24.2066Z"
          fill="white"
      />
    </svg>
  );
};