import React, { useEffect, useState, useRef } from "react";
import { useSwipeable } from "react-swipeable";
import SmalLoader from "./../ui/loading/Loaders";
import { useTranslation } from "react-i18next";

const MobileFilterElementsWrap = ({
  title = "",
  view,
  children,
  loading = false,
  openLayer = false,
  callbackClick = f=>f,
}) => {
  const [open, setOpen] = useState(false);
  const contentRef = useRef(null);
  const wrapperRef = useRef(null);
  const mapLayerRef = useRef(null);
  const [styleLayer, setStyleLayer] = useState({});
  const [styleMapLayer, setStyleMapLayer] = useState({
    height: "calc(100vh - 73px - env(safe-area-inset-bottom))",
    // height: "144px",
  });
  const [styleWrap, setStyleWrap] = useState({});

  // const handlers = useSwipeable({
  //     onSwipedUp: () => openInterfaceAction(),
  //     onSwipedDown: () => closeInterfaceAction(),
  //     swipeDuration: 500,
  //     preventScrollOnSwipe: true,
  //     trackMouse: true
  // });

  useEffect(() => {
    if (openLayer) {
      openInterfaceAction();
    } else {
      closeInterfaceAction();
    }
  }, [openLayer]);

  const renderTitle = () => {
    const { t, i18n } = useTranslation();
    if (!loading) {
      switch (view) {
        case 2:
          return (
            <div className="mobile-page-filters--layer_head-row fade-block">
              <h4>{title}</h4>

              <button onClick={closeInterfaceAction}>
                {" "}
                {/*{t("Приховати")}*/}
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.31299 6.31252L17.6882 17.6877"
                    stroke="#242328"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6.31299 17.6875L17.6882 6.31229"
                    stroke="#242328"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          );
        default:
          return <h4 className="fade-block">{title}</h4>;
      }
    } else {
      return (
        <h4 className="fade-block">
          <SmalLoader size={24} />
        </h4>
      );
    }
  };

  const openInterfaceAction = () => {
    let layerHeyght =
      wrapperRef.current !== null && wrapperRef.current.clientHeight;
    let contentHeight =
      contentRef.current !== null && contentRef.current.clientHeight;

    if (layerHeyght > contentHeight + 60) {
      setStyleMapLayer({
        height: `calc(100vh - ${contentHeight + 54}px - env(safe-area-inset-bottom) ) `,
      });
    } else {
      setStyleMapLayer({
        height: 144,
      });
    }
    // setOpen(true);
  };

  const closeInterfaceAction = () => {
    setStyleMapLayer({
      height: "calc(100vh - 92px - env(safe-area-inset-bottom))",
      // height: "calc(100vh - 60px - env(safe-area-inset-bottom))",
    });
    setOpen(false);
  };

  const onClickToggle = () => {
    if (openLayer) {
       closeInterfaceAction();
       callbackClick(false)
    } else {

       openInterfaceAction();
       callbackClick(true)
    }
  };

  return (
    <div
      ref={wrapperRef}
      style={styleWrap}
      className={
        openLayer
          ? "mobile-page-filters--wrap open-layer--wrap"
          : "mobile-page-filters--wrap"
      }
    >
      <div
        ref={mapLayerRef}
        style={styleMapLayer}
        className="map-mask-layer"
      ></div>
      <div
        //{...handlers}
        className={
          openLayer
            ? "mobile-page-filters--layer open-layer"
            : "mobile-page-filters--layer"
        }
        style={styleLayer}
      >
        <div
          onClick={onClickToggle}
          className="mobile-page-filters--layer_head"
        >
          {renderTitle()}
        </div>

        <div ref={contentRef} className="mobile-page-filters--layer_content">
          {!loading ? (
            <div className="fade-block">{children}</div>
          ) : (
            <div className="fade-block loader-layer-wrap">
              <SmalLoader size={100} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default MobileFilterElementsWrap;
