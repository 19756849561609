import React from "react";
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import '../../style/components/ImageSlider.pcss';

import slider1 from '../../media/about-us/slider1.jpg';
import slider2 from '../../media/about-us/slider2.jpg';
import slider3 from '../../media/about-us/slider3.jpeg';



const ImageSlider = () => {
    const settings = {
        // dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "0px",
        // autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        responsive: [{
            breakpoint: 600,
            settings: {
                dots:false,
                centerMode: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
            }}],
    };

    const images = [
        { src: slider1, alt: "Main Image", className: "main-image", width: 840, height: 448 },
        { src: slider3, alt: "Secondary Image", className: "secondary-image", width: 570, height: 380 },
        { src: slider2, alt: "Secondary Image", className: "secondary-image", width: 570, height: 380 },



        { src: slider3, alt: "Secondary Image", className: "secondary-image", width: 570, height: 380 },
        { src: slider2, alt: "Secondary Image", className: "secondary-image", width: 570, height: 380 },
    ];

    return (
        <div className="home-page--about-description3">
            <div className="container">
                {/*<div className="about-us-description--row">*/}
        <div className="slider-container">
            <Slider {...settings}>
                {images.map((image, index) => {
                  return (
                      <div key={index} className={'slick-slide-wrapper'}>
                          <img src={image.src} alt={image.alt} className={'slider-image'} />
                      </div>
                      // <div className={'review-card'}>
                      //     <div className={'card-item'} style={{width: `100%`}} >
                      //         <div key={index} className={index === 0 ? "image-card2 main-image2" : "image-card2 secondary-image2"}>
                      //             <img src={image.src} alt={image.alt} />
                      //         </div>
                      //     </div>
                      // </div>

                  )}
                  )
                }

            </Slider>
        {/*</div>*/}
        </div>
        </div>
        </div>
    );
};



export default ImageSlider;
