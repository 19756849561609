import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import useInputDebounce from "../../hooks/useInputDebounce";
import useSearchMove from "../../hooks/useSearchMove";
import { useTranslation } from "react-i18next";
import {IconButton} from "@mui/material";
import {useLocation} from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import {useQuery} from "../../hooks/useQuery";


const SearchByIdInput = ({type}) => {
  const { t, i18n } = useTranslation();
    const search = useSearchMove(type);
    const location = useLocation();
    const query = useQuery();
  const [valueInput, setValueInput] = useState("");
  const [valueInputReal, setValueInputReal] = useState("");
  const [isInit, setIsInit] = useState(false);
  const debounced= useInputDebounce(valueInput, 1200);

  useEffect(()=>{

          if (debounced) {
              let overriddenData = { id: debounced };
              search.moveToFilterOverridden(overriddenData);
          } else {
              if (isInit){
                      search.moveToFilterOverridden();
              }

          }
  },[debounced])
    useEffect(()=>{
            const param = query.get('id');
            if(!param){
                setValueInputReal('')
            }else{
                if (!isInit){
                    setValueInputReal(param)
                }
            }
    },[location])

  useEffect(()=>{

      setIsInit(true)
  },[])


  const handlerChange = (e) => {
         setValueInput(e.target.value);
         setValueInputReal(e.target.value);
  };
    const handlerChangeClear = (e) => {
        const clear=''
        search.moveToFilterOverridden({id:clear});
        // setValueInput(Math.random().toString());
        setValueInput(clear);
        // setValueInputReal(clear);
    };
  return (
    <div className="filter-fields-group__cell-input search-from-id">
      <div className="icon-search">
          <IconButton type="button" sx={{ p: '10px' }} onClick={handlerChangeClear} >
              {valueInputReal && <CloseIcon />}
          </IconButton>

      </div>

      <TextField
        // value={valueInput}
        value={valueInputReal}
        onChange={handlerChange}
        id="search-from-id"
        label={t("Пошук за ID")}
        variant="filled"
      />
    </div>
  );
};

SearchByIdInput.propTypes = {};

export default SearchByIdInput;
