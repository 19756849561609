import React from "react";
import NewsKimolos from "../media/News_Kimolos.jpg";
import NewsBreeze from "../media/News_Breeze.jpg";
import NewsModern from "../media/News_Modern.jpg";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import DialogBooking from "./Dialog/DialogBooking";


const HomePageNews = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1.2,
    slidesToScroll: 1,
  };

  // const handleButtonClick = () => {
  //   history.push("/catalog");
  // };

 

  return (
      <div className="home-page--news">
        <div className="container">
          <div className="home-page--news_title">
            <h1>{t("Новобудови без комісії")}</h1>
            <p>
              {t(
                  "Домінанта офіційний партнер найкращих новобудов міста Одеса. Купуйте з нами та не платіть комісію"
              )}
            </p>
          </div>
        </div>
        {/* Desktop */}
        <div className="home-page--news__list mobile-hidden">
          <div className="home-page--news__item">
            <div className="home-page--news__item-thumbnail">
              <img src={NewsKimolos} alt="news Kimolos" />
              <div className="mask--layer">
                <div className="mask--layer__label">
                  0%
                  <div className="dsc">{t("Комісія")}</div>
                </div>
              </div>
            </div>
            <div className="home-page--news__item-text">
              <h3>{t("ЖК Кімолос")}</h3>
              <p>
                {t(
                    "18-поверховий проєкт бізнес класу в приморському районі з панорамним видом на море, парки та пляжі Аркадії"
                )}
                &emsp;&emsp;&nbsp; &emsp;&emsp;&nbsp; &emsp;&emsp;&nbsp;
              </p>
            </div>
          </div>
          <div className="home-page--news__item">
            <div className="home-page--news__item-thumbnail">
              <img src={NewsBreeze} alt="news Breeze" />
              <div className="mask--layer">
                <div className="mask--layer__label">
                  0%
                  <div className="dsc">{t("Комісія")}</div>
                </div>
              </div>
            </div>
            <div className="home-page--news__item-text">
              <h3>{t("ЖК Breeze Marine")}</h3>
              <p>
                {t(
                    "Для короткострокової або довгострокової оренди. Проєкт апарт-комплексів на узбережжі Чорного моря. Басейн і зона відпочинку на даху"
                )}
              </p>
            </div>
          </div>
          <div className="home-page--news__item">
            <div className="home-page--news__item-thumbnail">
              <img src={NewsModern} alt="news Modern" />
              <div className="mask--layer">
                <div className="mask--layer__label">
                  0%
                  <div className="dsc">{t("Комісія")}</div>
                </div>
              </div>
            </div>
            <div className="home-page--news__item-text">
              <h3>{t("ЖК Modern")}</h3>
              <p>
                {t(
                    "Багатосекційний проєкт на 6 станції Фонтана. Нестандартний дизайн фасадів, внутрішній простір для мешканців, просторе лобі та консьєрж-сервіс"
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="show-all--row mobile-hidden">
          <DialogBooking

              showIcon={true}
              className="show-all-form"
              model={{}}
              textOrder={t("Дізнатись детальніше")}
          />
        </div>

      {/* Mobile */}
      <div className="home-page--news-mobile desctop-hidden">
        <Slider {...settings}>
          <div className="home-page--news-mobile--item">
            <div className="home-page--news__item">
              <div className="home-page--news__item-thumbnail">
                <img src={NewsKimolos} alt="news Kimolos" />

                <div className="mask--layer">
                  <div className="mask--layer__label">
                    0%
                    <div className="dsc">{t("Комісія")}</div>
                  </div>
                </div>
              </div>

              <div className="home-page--news__item-text">
                <h3>{t("ЖК Кімолос")}</h3>
                <p>
                  {t(
                    "18-поверховий проєкт бізнес класу в приморському районі з панорамним видом на море, парки та пляжі Аркадії",
                  )}{" "}
                </p>
              </div>
            </div>
          </div>

          <div className="home-page--news-mobile--item">
            <div className="home-page--news__item">
              <div className="home-page--news__item-thumbnail">
                <img src={NewsBreeze} alt="news Breeze" />

                <div className="mask--layer">
                  <div className="mask--layer__label">
                    0%
                    <div className="dsc">{t("Комісія")}</div>
                  </div>
                </div>
              </div>

              <div className="home-page--news__item-text">
                <h3>{t("ЖК Breeze Marine")}</h3>

                <p>
                  {t(
                    "Для короткострокової або довгострокової оренди. Проєкт апарт-комплексів на узбережжі Чорного моря. Басейн і зона відпочинку на даху",
                  )}{" "}
                </p>
              </div>
            </div>
          </div>

          <div className="home-page--news-mobile--item">
            <div className="home-page--news__item">
              <div className="home-page--news__item-thumbnail">
                <img src={NewsModern} alt="news Modern" />

                <div className="mask--layer">
                  <div className="mask--layer__label">
                    0%
                    <div className="dsc">{t("Комісія")}</div>
                  </div>
                </div>
              </div>

              <div className="home-page--news__item-text">
                <h3>{t("ЖК Modern")}</h3>
                <p>
                  {t(
                    "Багатосекційний проєкт на 6 станції Фонтана. Нестандартний дизайн фасадів, внутрішній простір для мешканців, просторе лобі та консьєрж-сервіс",
                  )}{" "}
                </p>
              </div>
            </div>
          </div>
        </Slider>


        <div className="show-all--row">

          <div className="viewing-action-row">
            <DialogBooking

                showIcon={true}
                className="show-all-form"
                model={{}}
                textOrder={t("Дізнатись детальніше")}
            />
          </div>
        </div>


      </div>
    </div>
  );
};
export default HomePageNews;
