import React from "react";
import { Pagination as PaginationOuter } from "react-laravel-paginex";
import useSearchMove from "/src/hooks/useSearchMove";
const Pagination = ({ meta, type = "catalog",callBack=f=>f }) => {

    const {total}=meta
  const search = useSearchMove(type);
    // const dispatch = useDispatch();
    if (total<=0){
        return null
    }
  return (
    <PaginationOuter
      changePage={(p) => {
          callBack(true);
          search.moveToFilterPaginate(p.page);
      }}
      data={meta}
    />
  );
};
export default Pagination;
