import React, {useContext, useEffect} from "react";
import HomePagePartners from "/src/components/HomePagePartners";
import HomePageAbout from "/src/components/HomePageAbout";
import CooperationWork from "/src/components/CooperationWork";
import AppFooter from "/src/components/AppFooter";
import { useDispatch } from "react-redux";
import { setResetSelectedFilter } from "../../store/reducers/selectedFilterData";
import HomePageRealtorsPromo from "../../components/HomePageRealtorsPromo";
import PrimaryCarousel from "../../components/PrimaryCarousel/PrimaryCarousel";
import WhyYou from "../../components/WhyYou";
import AppHeader from "../../components/AppHeader";
import HomePageBestDeals from "../../components/HomePageBestDeals";
import HomePageNews from "../../components/HomePageNews";
import {clearNavigationState} from "../../store/reducers/navigationState";
import {MobileFilterNavigationContext} from "../../providers/MobileFilterNavigationProvider";

const Home = (props) => {
  const dispatch = useDispatch();
    // const { stateMobileFilterNavigation, dispatchMobileFilterNavigation,refIsPaginated,refShowAdvancedFilterMobile  } = useContext(MobileFilterNavigationContext)
  useEffect(() => {
    // todo reset filter
    dispatch(setResetSelectedFilter());
    dispatch(clearNavigationState());

  }, []);

  return (
    <div className="home-page">
      <AppHeader />

      <PrimaryCarousel />
      <HomePageNews />

      <HomePageRealtorsPromo />

      <HomePageBestDeals />

      <HomePageAbout />

      <CooperationWork />

      <WhyYou />

      {/*<NewsHomePage />*/}
      <HomePagePartners />
      <AppFooter />
    </div>
  );
};
export default Home;
