import React, { useEffect, useState } from "react";
import SelectItems from "../ui/selects/SelectItems";
import {useSelector } from "react-redux";
import useSearchMove from "../../hooks/useSearchMove";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {useLocation} from "react-router-dom";
import {useQuery} from "../../hooks/useQuery";

const PriceSelect = ({ type }) => {
  const { t, i18n } = useTranslation();
  const items = useSelector((state) => state.filter.value.pricePresets);
  const location = useLocation();
  const query = useQuery();
  const [selectedItems, setSelectedItems] = useState(null);


  const search = useSearchMove(type);
  const handlerSelectChange = (event, newValue) => {

    if (newValue) {
      let overriddenData = { price: newValue?.slug ?? "" };
      search.moveToFilterOverridden(overriddenData);
    } else {
      if (items.length) {
        let overriddenData = { price: items[0].slug };
        search.moveToFilterOverridden(overriddenData);
      }
    }
    setSelectedItems(newValue);
  };

  useEffect(()=>{

    const param = query.get('price');
    if (items && items.length && param) {
      let filtered = items.find((e) => e.slug === param);
      if(!filtered) {
        filtered= _.first(items)
      }
      setSelectedItems(filtered);
    }
  },[location])
  useEffect(() => {
    if (items.length) {
        const param = query.get('price');
        let filtered = items.find((e) => e.slug === param);
        if(!filtered) {
          filtered= _.first(items)
        }
        setSelectedItems(filtered);
    }
  }, [items]);
  return (
    <React.Fragment>
      <SelectItems
        items={items}
        selected={selectedItems}
        label={t("Ціна")}
        multiple={false}
        callbackChange={handlerSelectChange}
      />
    </React.Fragment>
  );
};

export default PriceSelect;
