import { configureStore } from "@reduxjs/toolkit";
import filter from "./reducers/filter";
import selectedFilterData from "./reducers/selectedFilterData";
import objects from "./reducers/objects";
import object from "./reducers/object";
import activeItem from "./reducers/activeItem";
import Logger from "./middlewares/Logger";
import CounterWatcher from "./middlewares/CounterWatcher";
import previousResults from "./reducers/previousResults";
import bestProposals from "./reducers/bestProposals";
import favorites from "./reducers/favorites";
import navigationState from "./reducers/navigationState";

// const logger = store => next => action => {

//     let result = next(action)

//     return result
// }
// let ps='{}'
// if (typeof window !== 'undefined' && window){
//     ps=window.__PRELOADED_STATE__;
// }
//window?.__PRELOADED_STATE__;
export default configureStore({
  reducer: {
    filter: filter,
    selectedFilterData: selectedFilterData,
    objects: objects,
    object: object,
    activeItem: activeItem,
    previousResults: previousResults,
    bestProposals: bestProposals,
    favorites: favorites,
    navigationState: navigationState,
  },
  middleware: [Logger, CounterWatcher],
  devTools: true,
  preloadedState: window.__PRELOADED_STATE__
  // preloadedState: ps
});
