import React, { useEffect } from "react";
import AppHeader from "/src/components/AppHeader";
import AppFooter from "/src/components/AppFooter";
import { useDispatch } from "react-redux";
import { setResetSelectedFilter } from "../../store/reducers/selectedFilterData";
import PrimaryCarouseUserSuccess from "../../components/PrimaryCarousel/PrimaryCarouseUserSuccess";
import {scrollTopAction} from "../../utils/common";

const UserSuccess = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    // todo reset filter
    dispatch(setResetSelectedFilter());
      scrollTopAction();
  }, []);

  return (
    <div className="home-page user-success-page-wrapper">
      <AppHeader />

      <PrimaryCarouseUserSuccess />

      {/*<HomePageNews />*/}

      {/*<HomePageBestDeals />*/}

      {/*<HomePageAbout />*/}

      {/*<CooperationWork />*/}

      {/*<WhyYou />*/}

      {/*<NewsHomePage />*/}

      <AppFooter />
    </div>
  );
};

// class Home extends React.Component {
//
//
//
//   render() {
//     return (
//       <div className="home-page">
//         <AppHeader />
//
//         <PrimaryCarousel />
//
//         <HomePageNews />
//
//         <HomePageBestDeals />
//
//         <HomePageAbout />
//
//         <CooperationWork />
//
//         <WhyYou />
//
//         {/*<NewsHomePage />*/}
//
//         <AppFooter />
//       </div>
//     );
//   }
// }
//
export default UserSuccess;
