import React from "react";

import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import PartnerCador from "../media/partners/kador.jpg";
import PartnerBudova from "../media/partners/budova.png";
import PartnerKona from "../media/partners/kona1.png";
import PartnerHanner from "../media/partners/hanner.png";
import PartnerOdecom from "../media/partners/odecom1.png";
import PartnerKadorr from "../media/partners/Kadorr_logo.jpeg";
import PartnerStikon from "../media/partners/Stikon_logo1.jpg";
import PartnerAquarelle from "../media/partners/Aquarelle_logo1.jpg";
import PartnerZezman from "../media/partners/Zezman_logo1.jpg";
import PartnerZars from "../media/partners/Zars_logo1.jpg";

const settings2 = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1.2,
        slidesToScroll: 1,
      },
    },
  ],
};
const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        dots: false,
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

const HomePagePartners = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="home-page--partners">
      <div className="container">
        <div className="home-page--partners__title">
          <div className="cell">
            <h2> {t("Партнери, якими ми пишаемось")}</h2>
          </div>
        </div>

        <div className="home-page--best-deals-tabs">
          <div className="home-page--best-deals-tabs__content">
            <div className="home-page--best-deals-mobile-list">
              <Slider {...settings} key={"mob_deals"}>
                <img
                  className={"partnerImage"}
                  src={PartnerKadorr}
                  alt="Kadorr group"
                />
                <img className={"partnerImage"} src={PartnerKona} alt="Kona" />
{/*                <img
                  className={"partnerImage"}
                  src={PartnerHanner}
                  alt="Hanner"
                />*/}
                <img
                  className={"partnerImage"}
                  src={PartnerOdecom}
                  alt="Odecom"
                />
                <img
                    className={"partnerImage"}
                    src={PartnerStikon}
                    alt="Stikon"
                />
                <img
                    className={"partnerImage"}
                    src={PartnerAquarelle}
                    alt="Aquarelle"
                />
                <img
                    className={"partnerImage"}
                    src={PartnerZezman}
                    alt="Zezman"
                />
                <img
                    className={"partnerImage"}
                    src={PartnerZars}
                    alt="Zars"
                />
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HomePagePartners;
