import React from "react";
import { useTranslation } from "react-i18next";
import AddToFavorites from "../../ui/AddToFavorites";
import { Link } from "react-router-dom";
import SimilarItemDetailsList from "./SimilarItemDetailsList";

const SimilarItem = ({ type, item = {} }) => {
    const { t, i18n } = useTranslation();
    return (
        <div className="home-page--best-deals-list--item">
            <div className="home-page--best-deals-list--item_thumbnail">
                <img src={item.image.mini} alt="deals title" />
                <Link to={`/${item.type}/${item.slug}`}>
                    <div className="home-page--best-deals-list--item_thumbnail-mask">
                        <div className="like-cell">
                            <AddToFavorites id={item.id} />
                        </div>
                        <div className="info-cell  a1">
                            <div className="price">
                                {item.price_for_meter} $/м²
                                <div className="total">{item.price} $</div>
                            </div>
                            <div className="id-label">ID {item.id}</div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="home-page--best-deals-list--item_details">
                <Link to={`/${item.type}/${item.slug}`}>
                    <h3>{item.title}</h3>
                </Link>
                <div className="location">
                    {item.district_name}, {item.city_name}
                </div>
                <SimilarItemDetailsList item={item} />
            </div>
        </div>
    );
};
export default SimilarItem;
