import { createSlice } from "@reduxjs/toolkit";

export const navigationStateSlice = createSlice({
  name: "navigationState",
  initialState: {
    apartmentResultsOpen: false,
    commerceResultsOpen: false,
    houseResultsOpen: false,
    plotsResultsOpen: false,
    // activeModelIdToScroll: null,
    activeScrollPosition: 0,
  },
  reducers: {

    clearNavigationState: (state, action) => {
          state.apartmentResultsOpen=false
          state.commerceResultsOpen= false
          state.houseResultsOpen= false
          state.plotsResultsOpen= false
          state.activeModelIdToScroll= null
          state.activeScrollPosition= 0
    },

    setApartmentResultsOpen: (state, action) => {
      state.apartmentResultsOpen = action.payload;
    },
    setCommerceResultsOpen: (state, action) => {
      state.commerceResultsOpen = action.payload;
    },
    setHouseResultsOpen: (state, action) => {
      state.houseResultsOpen = action.payload;
    },
    setPlotsResultsOpen: (state, action) => {
      state.plotsResultsOpen = action.payload;
    },
    setActiveModelIdToScroll: (state, action) => {
      state.activeModelIdToScroll = action.payload;
    },
    setActiveScrollPosition: (state, action) => {
      state.activeScrollPosition = action.payload;
    },
  },
});
// Action creators are generated for each case reducer function
export const { setApartmentResultsOpen, setCommerceResultsOpen,
  setHouseResultsOpen, setPlotsResultsOpen,setActiveScrollPosition,clearNavigationState} =
    navigationStateSlice.actions;
export default navigationStateSlice.reducer;
