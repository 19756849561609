import React, { useEffect, useState } from "react";
import SelectItems from "../ui/selects/SelectItems";
import { useDispatch, useSelector } from "react-redux";
import { setLandPlotsVariants } from "../../store/reducers/selectedFilterData";
import useSearchMove from "../../hooks/useSearchMove";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {useLocation} from "react-router-dom";
import {useQuery} from "../../hooks/useQuery";

const PlotsFormVariantSelect = ({ type }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const query = useQuery();
  const items = useSelector((state) => state.filter.value.landPlotsVariants);
  const [selectedItems, setSelectedItems] = useState(null);

  const search = useSearchMove(type);
  const handlerSelectChange = (event, newValue) => {
    dispatch(setLandPlotsVariants(newValue?.slug ?? ""));
    let overriddenData = { landPlotsVariants: newValue?.slug ?? "" };
    search.moveToFilterOverridden(overriddenData);
  };

  useEffect(()=>{

    const param = query.get('landPlotsVariants');
    if (items && items.length && param) {
      let filtered = items.find((e) => e.slug === param);
      if(!filtered) {
        filtered= _.first(items)
      }
      setSelectedItems(filtered);
    }
  },[location])

  useEffect(() => {
    const param = query.get('landPlotsVariants');

    if (items && items.length) {
      const filtered = items.find((e) => e.slug === param);
      setSelectedItems(filtered);
    }
  }, [items]);
  return (
    <React.Fragment>
      <SelectItems
        items={items}
        selected={selectedItems}
        label={t("Форма ділянки")}
        multiple={false}
        callbackChange={handlerSelectChange}
      />
    </React.Fragment>
  );
};

export default PlotsFormVariantSelect;
