import React from 'react'
import MobileFilterNavigationProvider from "../providers/MobileFilterNavigationProvider";

const withMobileFilterNavigationProvider = (Component) => {
  return function (props) {
    return (
      <MobileFilterNavigationProvider>
        <Component {...props} />
      </MobileFilterNavigationProvider>
    )
  }
}
export default withMobileFilterNavigationProvider
