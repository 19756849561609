import React from "react";

export const MobilePlaceIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 18.75C19.6569 18.75 21 17.4069 21 15.75C21 14.0931 19.6569 12.75 18 12.75C16.3431 12.75 15 14.0931 15 15.75C15 17.4069 16.3431 18.75 18 18.75Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26 15.6923C26 22.6154 18 28 18 28C18 28 10 22.6154 10 15.6923C10 13.6522 10.8429 11.6956 12.3431 10.253C13.8434 8.81044 15.8783 8 18 8C20.1217 8 22.1566 8.81044 23.6569 10.253C25.1571 11.6956 26 13.6522 26 15.6923V15.6923Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" stroke="black" />
    </svg>
  );
};

export const MobileSettingFilterIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3 6H6" stroke="#3F6DB5" strokeWidth="1.5" />
      <path d="M3 18H6" stroke="#3F6DB5" strokeWidth="1.5" />
      <path d="M14 6L21 6" stroke="#3F6DB5" strokeWidth="1.5" />
      <path d="M14 18L21 18" stroke="#3F6DB5" strokeWidth="1.5" />
      <path d="M3 12H15" stroke="#3F6DB5" strokeWidth="1.5" />
      <rect
        x="8"
        y="4"
        width="4"
        height="4"
        rx="1.5"
        stroke="#3F6DB5"
        strokeWidth="1.5"
      />
      <rect
        x="8"
        y="16"
        width="4"
        height="4"
        rx="1.5"
        stroke="#3F6DB5"
        strokeWidth="1.5"
      />
      <rect
        x="17"
        y="10"
        width="4"
        height="4"
        rx="1.5"
        stroke="#3F6DB5"
        strokeWidth="1.5"
      />
    </svg>
  );
};

// ===================

export const MobileSelectedApartmentRoundIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 13.0635L18 9L26 13.0635V23.2222L18 27.2857L10 23.2222V13.0635Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" stroke="black" />
    </svg>
  );
};

export const MobileFilterApartmentIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 5.06349L10 1L18 5.06349V15.2222L10 19.2857L2 15.2222V5.06349Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const MobileSelectedPlotsRoundIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="18" cy="18" r="17.5" fill="white" stroke="black" />
      <path
        d="M22.0092 17.2969L26.1456 19.3979L17.8729 23.5999L9.6001 19.3979L13.7365 17.2969"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.6001 14.802L17.8729 10.5999L26.1456 14.802L17.8729 19.004L9.6001 14.802Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const MobileFilterPlotsIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4091 10.6969L18.5455 12.7979L10.2728 17L2 12.7979L6.13638 10.6969"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 8.202L10.2728 3.99997L18.5455 8.202L10.2728 12.404L2 8.202Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const MobileSelectedHousesRoundIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="18" cy="18" r="17.5" fill="white" stroke="black" />
      <path
        d="M9 14.2667L18 10L27 14.2667V24.9333L18 25L9 24.9333V14.2667Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const MobileFilterHousesIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 7.05556L10 3.5L17 7.05556V15.9444L10 16L3 15.9444V7.05556Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const MobileSelectedCommercesRoundIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="18" cy="18" r="17.5" fill="white" />
      <circle cx="18" cy="18" r="17.5" fill="white" />
      <circle cx="18" cy="18" r="17.5" fill="white" />
      <circle cx="18" cy="18" r="17.5" fill="white" />
      <circle cx="18" cy="18" r="17.5" fill="white" />
      <circle cx="18" cy="18" r="17.5" fill="white" />
      <circle cx="18" cy="18" r="17.5" stroke="black" />
      <path
        d="M10 12.84L17.56 9L25.12 12.84V22.44L17.56 26.28L10 22.44V12.84Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.493 22.08V13.62H18.393V22.08H17.493ZM17.817 21.108C17.313 21.108 16.83 21.042 16.368 20.91C15.906 20.772 15.534 20.595 15.252 20.379L15.747 19.281C16.017 19.473 16.335 19.632 16.701 19.758C17.073 19.878 17.448 19.938 17.826 19.938C18.114 19.938 18.345 19.911 18.519 19.857C18.699 19.797 18.831 19.716 18.915 19.614C18.999 19.512 19.041 19.395 19.041 19.263C19.041 19.095 18.975 18.963 18.843 18.867C18.711 18.765 18.537 18.684 18.321 18.624C18.105 18.558 17.865 18.498 17.601 18.444C17.343 18.384 17.082 18.312 16.818 18.228C16.56 18.144 16.323 18.036 16.107 17.904C15.891 17.772 15.714 17.598 15.576 17.382C15.444 17.166 15.378 16.89 15.378 16.554C15.378 16.194 15.474 15.867 15.666 15.573C15.864 15.273 16.158 15.036 16.548 14.862C16.944 14.682 17.439 14.592 18.033 14.592C18.429 14.592 18.819 14.64 19.203 14.736C19.587 14.826 19.926 14.964 20.22 15.15L19.77 16.257C19.476 16.089 19.182 15.966 18.888 15.888C18.594 15.804 18.306 15.762 18.024 15.762C17.742 15.762 17.511 15.795 17.331 15.861C17.151 15.927 17.022 16.014 16.944 16.122C16.866 16.224 16.827 16.344 16.827 16.482C16.827 16.644 16.893 16.776 17.025 16.878C17.157 16.974 17.331 17.052 17.547 17.112C17.763 17.172 18 17.232 18.258 17.292C18.522 17.352 18.783 17.421 19.041 17.499C19.305 17.577 19.545 17.682 19.761 17.814C19.977 17.946 20.151 18.12 20.283 18.336C20.421 18.552 20.49 18.825 20.49 19.155C20.49 19.509 20.391 19.833 20.193 20.127C19.995 20.421 19.698 20.658 19.302 20.838C18.912 21.018 18.417 21.108 17.817 21.108Z"
        fill="black"
      />
    </svg>
  );
};
export const MobileFilterCommercesIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 5.55556L10 2L17 5.55556V14.4444L10 18L3 14.4444V5.55556Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.49 11.155C12.49 11.647 12.31 12.07 11.95 12.424C11.59 12.772 11.071 12.988 10.393 13.072V14.08H9.493V13.099C9.049 13.075 8.626 12.997 8.224 12.865C7.822 12.733 7.498 12.571 7.252 12.379L7.747 11.281C7.975 11.449 8.242 11.59 8.548 11.704C8.854 11.818 9.169 11.89 9.493 11.92V10.417C9.067 10.315 8.707 10.201 8.413 10.075C8.119 9.949 7.873 9.766 7.675 9.526C7.477 9.28 7.378 8.956 7.378 8.554C7.378 8.056 7.558 7.63 7.918 7.276C8.284 6.916 8.809 6.697 9.493 6.619V5.62H10.393V6.601C11.131 6.661 11.74 6.844 12.22 7.15L11.77 8.257C11.32 7.999 10.861 7.843 10.393 7.789V9.319C10.813 9.415 11.167 9.526 11.455 9.652C11.749 9.772 11.995 9.955 12.193 10.201C12.391 10.441 12.49 10.759 12.49 11.155ZM8.827 8.482C8.827 8.632 8.884 8.755 8.998 8.851C9.112 8.947 9.277 9.028 9.493 9.094V7.816C9.265 7.87 9.097 7.957 8.989 8.077C8.881 8.191 8.827 8.326 8.827 8.482ZM10.393 11.884C10.609 11.836 10.771 11.758 10.879 11.65C10.987 11.542 11.041 11.413 11.041 11.263C11.041 11.113 10.984 10.99 10.87 10.894C10.762 10.798 10.603 10.714 10.393 10.642V11.884Z"
        fill="black"
      />
    </svg>
  );
};

export const MobileSelectedFavoriteRoundIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="18" cy="18" r="17.5" fill="white" stroke="black" />
      <path
        d="M9 14.2667L18 10L27 14.2667V24.9333L18 25L9 24.9333V14.2667Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="18" cy="18" r="17.5" fill="white" stroke="black" />
      <path
        d="M25.071 19.1421L19.4141 24.799C18.6331 25.58 17.3667 25.58 16.5857 24.799L10.9288 19.1421C8.9762 17.1895 8.9762 14.0237 10.9288 12.071C12.8814 10.1184 16.0473 10.1184 17.9999 12.071C19.9525 10.1184 23.1183 10.1184 25.071 12.071C27.0236 14.0237 27.0236 17.1895 25.071 19.1421Z"
        stroke="#242328"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
