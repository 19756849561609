import APP_CONFIG from "/src/config.js";
import React from 'react';
import Toastify from 'toastify-js'

// import 'react-toastify/dist/ReactToastify.css';
import {t} from "i18next";
// import { useDispatch } from "react-redux";
// import { putObjects } from "../store/reducers/objects";

const ApiPort = {

  fetchCount: (query) => {

    const run = async (query) => {
      // const dispatch = useDispatch()
      // let d = query;
      return fetch(`${APP_CONFIG.apiBaseUrl}api/count?${query}`)
        .then((response) => {
            if (!response.ok) {
                let error = t('Щось пішло не так')
                switch (response.status) {
                    case 429:
                        throw new Error('too many request');
                    default:
                        throw new Error('something went wrong');
                }
            }
         return  response.json()
        })
        .then((data) => {
          return data;
        }).catch(e=>{
              let message = e.message
               switch (e.message) {
                   case 'too many request':
                       message = t('Занадто багато запитів спробуйте пізніше')
                       break
                   default:
                       message = t('Щось пішло не так')
                       break
               }
              Toastify({
                  text: message,
                  gravity: "bottom", // `top` or `bottom`
                  position: "center", // `left`, `center` or `right`,
                  quantity: 1,
                  duration: 2000,
                  style: {
                      background: "hsl(12, 87%, 59%)",
                  },
              }).showToast();
          });
    };
    return run(query).then((r) => r).catch(error=>{
        const em= 'error:: '+error
        // console.log('error____:: ',em)

    });
  },
};
export default ApiPort;
