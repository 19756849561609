import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    uk: {
        translation: {
            "Ласкаво просимо до React": "Ласкаво просимо до React",
            "Придбати": "Придбати",
            "Продати": "Продати",
            "Оцінити": "Оцінити",
            "працівників":"працівників",
            "щасливих клієнтів":"щасливих клієнтів",
            "Агенція нерухомості «Dominanta» працює на ринку нерухомості вже 12+ років і має експертизу в галузі купівлі та продажу житлової та комерційної нерухомості. З 2023 року агентство входить до структури Dominanta corporation.":"Агенція нерухомості «Dominanta» працює на ринку нерухомості вже 12+ років і має експертизу в галузі купівлі та продажу житлової та комерційної нерухомості. З 2023 року агентство входить до структури Dominanta corporation.",
            "Dominanta - твій ключ":"Dominanta - твій ключ",
            "в світ нерухомості":"в світ нерухомості",
            "Залиште свій номер телефону, і наш фахівець зв'яжеться з вами. Він поставить кілька запитань про вашу нерухомість і розмістить ваше оголошення на сайті":"Залиште свій номер телефону, і наш фахівець зв'яжеться з вами. Він поставить кілька запитань про вашу нерухомість і розмістить ваше оголошення на сайті",
            "Таунхаус":"Таунхаус",
            "Про компанію": "Про компанію",
            "Міжнародне агенство нерухомості":"Міжнародне агенство нерухомості",
            "Новини": "Новини",
            "Ім'я":"Ім'я",
            "ХОЧУ СТАТИ ЧАСТИНОЮ КОМАНДИ":"ХОЧУ СТАТИ ЧАСТИНОЮ КОМАНДИ",
            "До кожного клієнта ми закріплюємо персонального агента з нерухомості, який буде з вами з моменту підбору об'єкта нерухомості до отримання ключів":"До кожного клієнта ми закріплюємо персонального агента з нерухомості, який буде з вами з моменту підбору об'єкта нерухомості до отримання ключів",
            "За цей час ми зробили щасливішими понад 10 тисяч клієнтів, а наша репутація стала гарантом виконання наших обов'язків та поставленого результату":"За цей час ми зробили щасливішими понад 10 тисяч клієнтів, а наша репутація стала гарантом виконання наших обов'язків та поставленого результату",
            "Ми займаємося підбором об\'єктів первинної та вторинної житлової нерухомості, а також комерційної нерухомості. З нами ви можете не лише купувати, а й продавати об\'єкти нерухомості":"Ми займаємося підбором об\'єктів первинної та вторинної житлової нерухомості, а також комерційної нерухомості. З нами ви можете не лише купувати, а й продавати об\'єкти нерухомості",
            "Отримати добірку":"Отримати добірку",
            "Швидкий продаж":"Швидкий продаж",
            "Юридична безпека":"Юридична безпека",
            "Агентство нерухомості «Dominanta» створює можливості для реалізації бажань клієнтів на ринку нерухомості. Наші послуги є джерелом позитивних змін у житті наших клієнтів. Критерії нашої роботи - уявлення сучасної людини про першокласні послуги. Саме тому розвиток сервісу і кожного агента особисто - не просто гучна заява, а поступальна і копітка робота.":"Агентство нерухомості «Dominanta» створює можливості для реалізації бажань клієнтів на ринку нерухомості. Наші послуги є джерелом позитивних змін у житті наших клієнтів. Критерії нашої роботи - уявлення сучасної людини про першокласні послуги. Саме тому розвиток сервісу і кожного агента особисто - не просто гучна заява, а поступальна і копітка робота.",
            "Висока ціна":"Висока ціна",
            "Персональний менеджер":"Персональний менеджер",
            "Отримати безкоштовну консультацію":"Отримати безкоштовну консультацію",
            "Контакти": "Контакти",
            "Залиште питання про нерухомість":"Залиште питання про нерухомість",
            "Ми знаємо, як це складно. Дозвольте нам допомогти!":"Ми знаємо, як це складно. Дозвольте нам допомогти!",
            "Заповніть заявку, і наш фахівець знайде ідеальну нерухомість для вас у потрібному районі":"Заповніть заявку, і наш фахівець знайде ідеальну нерухомість для вас у потрібному районі",
            "Квартири вторинний ринок":"Квартири вторинний ринок",
            "Квартири втор. ринок":"Квартири втор. ринок",
            "Квартиры вторичка":"Квартири вторинний ринок",
            "Ми в соціальних мережах":"Ми в соціальних мережах",
            "Ми гарантуємо швидкий продаж вашої нерухомості завдяки нашій великій базі покупців та ефективній маркетинговій стратегії":"Ми гарантуємо швидкий продаж вашої нерухомості завдяки нашій великій базі покупців та ефективній маркетинговій стратегії",
            "Ми забезпечимо повний юридичний супровід угоди, захищаючи ваші інтереси на кожному етапі":"Ми забезпечимо повний юридичний супровід угоди, захищаючи ваші інтереси на кожному етапі",
            "Ми допоможемо вам отримати максимальну вартість за вашу нерухомість, використовуючи точну оцінку ринку та переговорні навички":"Ми допоможемо вам отримати максимальну вартість за вашу нерухомість, використовуючи точну оцінку ринку та переговорні навички",
            "За вами буде закріплено персонального менеджера, котрий буде курирувати весь процес продажу і відповідати на всі ваші запитання":"За вами буде закріплено персонального менеджера, котрий буде курирувати весь процес продажу і відповідати на всі ваші запитання",
            "Допоможемо продати нерухомість швидко і вигідно":"Допоможемо продати нерухомість швидко і вигідно",
            "Добрий день. Хочу продати нерухомість.\nПотрібна консультація":"Добрий день. Хочу продати нерухомість.\nПотрібна консультація",
            "Розмістіть оголошення про продаж або дізнайтесь ринкову вартість нерухомості":"Розмістіть оголошення про продаж або дізнайтесь ринкову вартість нерухомості",
            "Квартири біля моря":"Квартири біля моря",
            "У нас один з найкращих і великих каталогів, де ви зможете знайти потрібний вам об'єкт в залежності від вартості, локації та інших переваг":"У нас один з найкращих і великих каталогів, де ви зможете знайти потрібний вам об'єкт в залежності від вартості, локації та інших переваг",
            "Квартири з терасами":"Квартири з терасами",
            "1-кімнатні":"1-кімнатні",
            "2-х кімнатні":"2-х кімнатні",
            "Dominanta - це":"Dominanta - це",
            "Дозвольте нам перетворити ваші мрії на реальність. З Dominanta ви завжди в центрі уваги":"Дозвольте нам перетворити ваші мрії на реальність. З Dominanta ви завжди в центрі уваги",
            "3-х кімнатні":"3-х кімнатні",
            "Дом":"Будинки",
            "Тип нерухомості":"Тип нерухомості",
            "Квартира":"Квартира",
            "Коментар":"Коментар",
            "Участки":"Участки",
            "Новобудова":"Новобудова",
            "Новобудови":"Новобудови",
            "Новострой":"Новобудови",
            "Здані ЖК":"Здані ЖК",
            "Як це працює":"Як це працює",
            "Будуються ЖК":"Будуються ЖК",
            "З готовим ремонтом":"З готовим ремонтом",
            "Новобудова на виплату":"Новобудова на виплату",
            "Акційні пропозиції":"Акційні пропозиції",
            "Новобудови в Аркадії":"Новобудови в Аркадії",
            "Новобудови в Центрі":"Новобудови в Центрі",
            "Новобудови у Київському районі":"Новобудови у Київському районі",
            "Новобудови у Суворівському районі":"Новобудови у Суворівському районі",
            "Новобудови у Малинівському районі":"Новобудови у Малинівському районі",
            "Будинки та Дачі":"Будинки та Дачі",
            "Таунхауси":"Таунхауси",
            "Ділянки біля моря":"Ділянки біля моря",
            "Наші співробітники - професіонали. Вони використовують свої знання, досвід і аналітику ринку для того, щоб ви отримали максимальну вигоду з угоди, і при цьому безпечно і з дотриманням інтересів усіх сторін.":"Наші співробітники - професіонали. Вони використовують свої знання, досвід і аналітику ринку для того, щоб ви отримали максимальну вигоду з угоди, і при цьому безпечно і з дотриманням інтересів усіх сторін.",
            "Ділянки в межах міста":"Ділянки в межах міста",
            "Ділянки за містом":"Ділянки за містом",
            "Комерція":"Комерція",
            "Каталог комерції":"Каталог комерції",
            "Офісні приміщення":"Офісні приміщення",
            "Приміщення вільного призначення":"Приміщення вільного призначення",
            "Виробничі приміщення":"Виробничі приміщення",
            "Магазини, салони":"Магазини, салони",
            "Ресторани, кафе, бари":"Ресторани, кафе, бари",
            "Склади, ангари":"Склади, ангари",
            "Паркінги":"Паркінги",
            "Агентство нерухомості Домінанта працює дванадцять років на одеському ринку":"Агентство нерухомості Домінанта працює дванадцять років на одеському ринку",
            "Чому вибирають нас":"Чому вибирають нас",
            "Гарантії та переваги роботи з нашим агенством":"Гарантії та переваги роботи з нашим агенством",
            "вся нерухомість Одеси":"вся нерухомість Одеси",
            "безпечний пошук нерухомості в Одесі":"безпечний пошук нерухомості в Одесі",
            "Дванадцять років на одеському ринку нерухомості":"Дванадцять років на одеському ринку нерухомості",
            "Допомога експерта":"Допомога експерта",
            "Отримайте п'ять пропозицій під ваш запит":"Отримайте п'ять пропозицій під ваш запит",
            "Будинки":"Будинки",
            "У Dominanta ми віримо, що довіра - це ключ до успішної співпраці. Ми пишаємося нашою репутацією і прагнемо до того, щоб кожен клієнт почувався в безпеці та знав, що його нерухомість у надійних руках. Ваша мрія про новий будинок - наша головна мета":"У Dominanta ми віримо, що довіра - це ключ до успішної співпраці. Ми пишаємося нашою репутацією і прагнемо до того, щоб кожен клієнт почувався в безпеці та знав, що його нерухомість у надійних руках. Ваша мрія про новий будинок - наша головна мета",
            "Ділянки":"Ділянки",
            "Коммерция":"Комерція",
            "Виберіть місто":"Виберіть місто",
            "Виберіть район":"Виберіть район",
            "Ми поважаємо індивідуальність вибору кожного клієнта, адже цілі наших клієнтів - наші цілі! Ми робимо все, щоб досягти їх. І робимо це не тому що мусимо, а тому що хочемо це робити. Ґрунтуючись на принципах нашої роботи - ясності, чесності та прозорості - ми гарантуємо високий рівень використовуваних схем і стандартів, а також професіоналізм і відповідальність усіх співробітників компанії.":"Ми поважаємо індивідуальність вибору кожного клієнта, адже цілі наших клієнтів - наші цілі! Ми робимо все, щоб досягти їх. І робимо це не тому що мусимо, а тому що хочемо це робити. Ґрунтуючись на принципах нашої роботи - ясності, чесності та прозорості - ми гарантуємо високий рівень використовуваних схем і стандартів, а також професіоналізм і відповідальність усіх співробітників компанії.",
            "Ціна":"Ціна",
            "Мін. ціна":"Мін. ціна",
            "Макс. ціна":"Макс. ціна",
            "Кількість кімнат":"Кількість кімнат",
            "Пошук":"Пошук",
            "Розширений пошук":"Розширений пошук",
            "Будинок":"Будинок",
            "Ділянка":"Ділянка",
            "Популярні запити":"Популярні запити",
            "Загальна площа":"Загальна площа",
            "Житлова площа":"Житлова площа",
            "Поверх":"Поверх",
            "поверх":"поверх",
            "поверховий":"поверховий",
            "поверхи":"поверхи",
            "Кількість поверхів":"Кількість поверхів",
            "Площа кухні":"Площа кухні",
            "Тип будівлі":"Тип будівлі",
            "Опалення":"Опалення",
            "Стіни":"Стіни",
            "Ремонт":"Ремонт",
            "Очистити фільтри":"Очистити фільтри",
            "Показати":"Показати",
            "пропозиції":"пропозиції",
            "Дивитись всі об'єкти":"Дивитись всі об'єкти",
            "Зв'яжіться з нами і дізнайтесь, як ми можемо допомогти вам продати вашу нерухомість швидко і вигідно!":"Зв'яжіться з нами і дізнайтесь, як ми можемо допомогти вам продати вашу нерухомість швидко і вигідно!",

            "Найкращі":"Найкращі",
            "років роботи":"років роботи",
            "філіалів по місту":"філіалів по місту",
            "спеціалістів по нерухомості":"спеціалістів по нерухомості",
            "угод за рік":"угод за рік",
            "Ми розмістимо ваше оголошення на популярних міських платформах для пошуку нерухомості. Наш фахівець з нерухомості організує особисті або онлайн покази вашого об'єкта для потенційних покупців.":"Ми розмістимо ваше оголошення на популярних міських платформах для пошуку нерухомості. Наш фахівець з нерухомості організує особисті або онлайн покази вашого об'єкта для потенційних покупців.",
            "На ринку нерухомості Одеси":"На ринку нерухомості Одеси",
            "Усі пропозиції":"Усі пропозиції",
            "Продаж та купівля нерухомості з АН Домінанта - це спосіб швидко і просто здійснити угоду":"Продаж та купівля нерухомості з АН Домінанта - це спосіб швидко і просто здійснити угоду",
            "Ріелтор агентства зробить всю роботу за вас і супроводжує на кожному етапі, аж до завершального. Тільки так можна отримати задоволеного клієнта":"Ріелтор агентства зробить всю роботу за вас і супроводжує на кожному етапі, аж до завершального. Тільки так можна отримати задоволеного клієнта",
            "Докладніше про нас":"Докладніше про нас",
            "фахівців":"фахівців",
            "з нерухомості":"з нерухомості",
            "об'єктів у базі":"об'єктів у базі",
            "новобудов у базі":"новобудов у базі",
            "11 філій по всьому місту, професійні співробітники, які в найкоротші терміни підберуть Вам найкраще вирішення квартирного питання":"11 філій по всьому місту, професійні співробітники, які в найкоротші терміни підберуть Вам найкраще вирішення квартирного питання",
            "Отримати консультацію":"Отримати консультацію",
            "Як відбувається співпраця":"Як відбувається співпраця",
            "Заявка":"Заявка",
            "Ми цінуємо кожного нашого клієнта та прагнемо надати лише найвищий рівень сервісу. Якість та професіоналізм – це основні принципи, якими ми керуємося в роботі. Тому, при зверненні до нас, ви можете бути впевнені, що вам буде надано досвідченого експерта, який спеціалізується саме на вашому типі нерухомості":"Ми цінуємо кожного нашого клієнта та прагнемо надати лише найвищий рівень сервісу. Якість та професіоналізм – це основні принципи, якими ми керуємося в роботі. Тому, при зверненні до нас, ви можете бути впевнені, що вам буде надано досвідченого експерта, який спеціалізується саме на вашому типі нерухомості",
            "Консультація":"Консультація",
            "Завдяки унікальній базі об'єктів та передовим цифровим технологіям. Ми надаємо клієнтам найбільш релевантну добірку об'єктів, які найкраще відповідають їхнім запитам та потребам":"Завдяки унікальній базі об'єктів та передовим цифровим технологіям. Ми надаємо клієнтам найбільш релевантну добірку об'єктів, які найкраще відповідають їхнім запитам та потребам",
            "Огляд":"Огляд",
            "Ми цінуємо ваш час та комфорт, тому зробимо все можливе, щоб ваше знайомство з нерухомістю було приємним та результативним. Наші тури – це незабутній досвід, який дозволить вам поринути у атмосферу обраного району. Наш агент покаже вам всі цікаві місця, розповість про нерухомість, її історію та архітектуру. Ви дізнаєтеся про інфраструктуру району та інші особливості, які допоможуть вам зробити правильний вибір":"Ми цінуємо ваш час та комфорт, тому зробимо все можливе, щоб ваше знайомство з нерухомістю було приємним та результативним. Наші тури – це незабутній досвід, який дозволить вам поринути у атмосферу обраного району. Наш агент покаже вам всі цікаві місця, розповість про нерухомість, її історію та архітектуру. Ви дізнаєтеся про інфраструктуру району та інші особливості, які допоможуть вам зробити правильний вибір",
            "Договір":"Договір",
            "Ми готові надати вам якісний супровід під час придбання нерухомості. Наші досвідчені юристи мають високу кваліфікацію та професійний підхід до роботи. Вони проведуть юридичне обстеження об'єкта нерухомості та оцінять усі юридичні та фінансові ризики, пов'язані з угодою":"Ми готові надати вам якісний супровід під час придбання нерухомості. Наші досвідчені юристи мають високу кваліфікацію та професійний підхід до роботи. Вони проведуть юридичне обстеження об'єкта нерухомості та оцінять усі юридичні та фінансові ризики, пов'язані з угодою",
            "Чому з Dominanta вигідно співпрацювати":"Чому з Dominanta вигідно співпрацювати",
            "Широкий спектр послуг":"Широкий спектр послуг",
            "Найкраща база об'єктів":"Найкраща база об'єктів",
            "Більш ніж 10-річний досвід":"Більш ніж 10-річний досвід",
            "Індивідуальний підхід до кожного":"Індивідуальний підхід до кожного",
            "Новобудови без комісії":"Новобудови без комісії",
            "Домінанта офіційний партнер найкращих новобудов міста Одеса. Купуйте з нами та не платіть комісію":"Домінанта офіційний партнер найкращих новобудов міста Одеса. Купуйте з нами та не платіть комісію",
            "Комісія":"Комісія",
            "Послуги":"Послуги",
            "Про нас":"Про нас",
            "Вакансії":"Вакансії",
            "Наші проєкти":"Наші проєкти",
            "Бонус":"Бонус",
            "Ми розуміємо, що кожен крок на шляху до вашої нової оселі може бути джерелом стресу та хвилювання. Але з нами все інакше":"Ми розуміємо, що кожен крок на шляху до вашої нової оселі може бути джерелом стресу та хвилювання. Але з нами все інакше",
            "Всі права захищені":"Всі права захищені",
            "Завантажити":"Завантажити",
            "Застосунок":"Застосунок",
            "Вгору":"Вгору",
            "Головна":"Головна",
            "Ми ретельно підбираємо і перевіряємо кожну пропозицію, щоб відповідати вашим очікуванням. Наші експерти забезпечать професійну фотосесію, створять привабливі оголошення та проведуть переговори з потенційними покупцями.":"Ми ретельно підбираємо і перевіряємо кожну пропозицію, щоб відповідати вашим очікуванням. Наші експерти забезпечать професійну фотосесію, створять привабливі оголошення та проведуть переговори з потенційними покупцями.",
            "Каталог об'єктів":"Каталог об'єктів",
            "Одеса":"Одеса",
            "результатів":"результатів",
            "Надихаємо довіру, створюємо затишок.":"Надихаємо довіру, створюємо затишок.",
            "Слідкуйте за новими пропозиціями – підписуйтесь на наші канали.":"Слідкуйте за новими пропозиціями – підписуйтесь на наші канали.",
            "Фільтри":"Фільтри",
            "Не вдається самостійно знайти нерухомість?":"Не вдається самостійно знайти нерухомість?",
            "Дайте відповідь на пару простих питань":"Дайте відповідь на пару простих питань",
            "і наш агент зробить усю роботу за вас!":"і наш агент зробить усю роботу за вас!",
            "Відповісти на питання":"Відповісти на питання",
            "Ще фільтри":"Ще фільтри",
            "Район":"Район",
            "Пошук за ID":"Пошук за ID",
            "Приховати":"Приховати",
            "Вартість":"Вартість",
            "В продажі":"В продажі",
            "кімнати":"кімнати",
            "кімната":"кімната",
            "поверх з":"поверх з",
            "Детальніше":"Детальніше",
            "Опис":"Опис",
            "Характеристики":"Характеристики",
            "Розташування":"Розташування",
            "Основні":"Основні",
            "Код об'єкта":"Код об'єкта",
            "Область":"Область",
            "Населений пункт":"Населений пункт",
            "Співпрацюючи з Dominanta, ви отримуєте не просто послугу - ви отримуєте впевненість і підтримку на кожному етапі. Наша команда досвідчених фахівців візьме на себе всі турботи: від первинної консультації до підписання договору":"Співпрацюючи з Dominanta, ви отримуєте не просто послугу - ви отримуєте впевненість і підтримку на кожному етапі. Наша команда досвідчених фахівців візьме на себе всі турботи: від первинної консультації до підписання договору",
            "Про квартиру":"Про квартиру",
            "Про комерцію":"Про комерцію",
            "Кімнатність":"Кімнатність",
            "Висота стелі":"Висота стелі",
            "Санвузол":"Санвузол",
            "Балкон":"Балкон",
            "Стан балкону":"Стан балкону",
            "Розмістіть оголошення":"Розмістіть оголошення",
            "Підготовка до продажу":"Підготовка до продажу",
            "Пошук покупців":"Пошук покупців",
            "Укладання договору":"Укладання договору",
            "Вікна":"Вікна",
            "Сторони світу":"Сторони світу",
            "Вид":"Вид",
            "Тераса":"Тераса",
            "Ми додамо фотографії та планування, а також створимо привабливий опис вашої пропозиції. Ви можете підписати договір з агентом в офісі або онлайн":"Ми додамо фотографії та планування, а також створимо привабливий опис вашої пропозиції. Ви можете підписати договір з агентом в офісі або онлайн",
            "Про будинок":"Про будинок",
            "Матеріал будівництва":"Матеріал будівництва",
            "Двір":"Двір",
            "Адреса":"Адреса",
            "Ріелтор об'єкту":"Ріелтор об'єкту",
            "Зверталися до філії на Армійській з приводу купівлі комерційного приміщення, менеджерка Ганна швидко зорієнтувала по варіантах і в нашому ціновому сегменті. У підсумку зупинилися на БЦ, що стоїть на Краснова ...":"Зверталися до філії на Армійській з приводу купівлі комерційного приміщення, менеджерка Ганна швидко зорієнтувала по варіантах і в нашому ціновому сегменті. У підсумку зупинилися на БЦ, що стоїть на Краснова ...",
            "Записати на перегляд":"Записати на перегляд",
            "Схожі об'єкти":"Схожі об'єкти",
            "Кільк. кімнат":"Кільк. кімнат",
            "Замовити дзвінок":"Замовити дзвінок",
            "Ваше ім'я": "Ваше ім'я",
            "Надіслати": "Надіслати",
            "З компанією співпрацюють понад 300 експертів у сфері нерухомості, які безперервно разом з Dominanta, проходять навчання для надання якісних послуг, а після угоди - клієнти оцінюють їхню роботу":"З компанією співпрацюють понад 300 експертів у сфері нерухомості, які безперервно разом з Dominanta, проходять навчання для надання якісних послуг, а після угоди - клієнти оцінюють їхню роботу",
            "Ознайомлений з": "Ознайомлений з",
            "Політикою конфіденційності": "Політикою конфіденційності",
            "та даю згоду на збір та обробку своїх персональних даних": "та даю згоду на збір та обробку своїх персональних даних",
            "Дякуємо":"Дякуємо",
            "Ми зв'яжемося з вами найближчим часом":"Ми зв'яжемося з вами найближчим часом",
            "Партнери, якими ми пишаемось":"Партнери, якими ми пишаемось",
            "96% клієнтів задоволені нашими послугами":"96% клієнтів задоволені нашими послугами",
            "З компанією співпрацюють понад 300 експертів у сфері нерухомості, які безперервно разом із Dominanta, проходять навчання для надання якісних послуг. А після угоди – клієнти оцінюють їхню роботу":'З компанією співпрацюють понад 300 експертів у сфері нерухомості, які безперервно разом із Dominanta, проходять навчання для надання якісних послуг. А після угоди – клієнти оцінюють їхню роботу',
            "Залишити відгук":'Залишити відгук',

            "Спеціалізація":"Спеціалізація",
            "Досвід роботи":"Досвід роботи",
            "Сегмент":"Сегмент",
            "Email":"Email",
            "Кількість угод":"Кількість угод",
            "Новобуди та вторинний ринок":"Новобуди та вторинний ринок",
            "16 років":"16 років",
            "Середній":"Середній",
            "Понад 200":"Понад 200",
            "Приміщення промислового призначення":"Приміщення промислового призначення",
            "Вторинний ринок нерухомості":"Вторинний ринок нерухомості",
            "14 років":"14 років",
            "Вищий за середній":"Вищий за середній",
            "Понад 500":"Понад 500",
            "Новобудова та вторинний ринок, комерція":"Новобудова та вторинний ринок, комерція",
            "8 років":"8 років",
            "Елітна нерухомість":"Елітна нерухомість",
            "Понад 450":"Понад 450",

            "Ріелторські послуги при купівлі та продажу":"Ріелторські послуги при купівлі та продажу",
            "Відгуки":"Відгуки",
            "Мої об'єкти":"Мої об'єкти",
            "Залишіть Ваш номер телефону та Вам передзвонять протягом 5 хвилин":"Залишіть Ваш номер телефону та Вам передзвонять протягом 5 хвилин",
            "Ваш відгук":'Ваш відгук',
            "Ваше повідомлення":"Ваше повідомлення",
            "ЖК Кімолос": "ЖК Кімолос",
            "18-поверховий проєкт бізнес класу в приморському районі з панорамним видом на море, парки та пляжі Аркадії": "18-поверховий проєкт бізнес класу в приморському районі з панорамним видом на море, парки та пляжі Аркадії",
            "ЖК Breeze Marine": "ЖК Breeze Marine",
            "Для короткострокової або довгострокової оренди. Проєкт апарт-комплексів на узбережжі Чорного моря. Басейн і зона відпочинку на даху":"Для короткострокової або довгострокової оренди. Проєкт апарт-комплексів на узбережжі Чорного моря. Басейн і зона відпочинку на даху",
            "ЖК Modern":"ЖК Modern",
            "Багатосекційний проєкт на 6 станції Фонтана. Нестандартний дизайн фасадів, внутрішній простір для мешканців, просторе лобі та консьєрж-сервіс":"Багатосекційний проєкт на 6 станції Фонтана. Нестандартний дизайн фасадів, внутрішній простір для мешканців, просторе лобі та консьєрж-сервіс",
            "Так":"Так",
            "Дача":"Дача",
            "Гараж":"Гараж",
            "Тип санвузла":"Тип санвузла",
            "Тип вікон":"Тип вікон",
            "Каталог будинків":"Каталог будинків",
            "Таунхауз":"Таунхауз",
            "Форма ділянки":"Форма ділянки",
            "Про ділянку":"Про ділянку",
            "Площа ділянки":"Площа ділянки",
            "Місце знаходження":"Місце знаходження",
            "Комунікації":"Комунікації",
            "сотки":"сотки",
            "Квартири":"Квартири",
            "Каталог ділянок":"Каталог ділянок",
            "ділянка":"ділянка",
            "Беремо на себе весь процес підготовки документів і проведення угоди. Організовуємо і супроводжуємо процес передачі завдатку, можна онлайн. Оплачуєте послуги компанії. Проводимо операцію, реєструємо право власності. Передаєте ключі.":"Беремо на себе весь процес підготовки документів і проведення угоди. Організовуємо і супроводжуємо процес передачі завдатку, можна онлайн. Оплачуєте послуги компанії. Проводимо операцію, реєструємо право власності. Передаєте ключі.",
            "Вибрані":"Вибрані",
            "Вибрані об'єкти":"Вибрані об'єкти",
            "13 червня 2024":"13 червня 2024",
            "15 квітня 2024":"15 квітня 2024",
            "16 грудня 2023":"16 грудня 2023",
            "9 жовтня 2023":"9 жовтня 2023",
            "Оленька Ковальска":"Оленька Ковальска",
            "Олена Казакова":"Олена Казакова",
            "Лана Акімова":"Лана Акімова",
            "Руслан Штарк":"Руслан Штарк",
            "Найкрутіше агенство нерухомості! Дякую за ваш ПРОФЕСІОНАЛІЗМ!!! Процвітання вам! Побільше вдячних клієнтів! Побільше угод!":"Найкрутіше агенство нерухомості! Дякую за ваш ПРОФЕСІОНАЛІЗМ!!! Процвітання вам! Побільше вдячних клієнтів! Побільше угод!",
            "Довго самі шукали підходящий варіант квартири в малоповерховому будинку, і неодноразово зверталися в агентство і не прогадали. Ріелтор швидко зорієнтував за наявністю об'єктів і в районі, що цікавить ...":"Довго самі шукали підходящий варіант квартири в малоповерховому будинку, і неодноразово зверталися в агентство і не прогадали. Ріелтор швидко зорієнтував за наявністю об'єктів і в районі, що цікавить ...",
            "Все було професійно і якісно. Вона зробила все швидко і професійно. Була завжди поруч, допомагала і радила. Усім рекомендую: вона чудовий і грамотний фахівець. Анно, спасибі!":"Все було професійно і якісно. Вона зробила все швидко і професійно. Була завжди поруч, допомагала і радила. Усім рекомендую: вона чудовий і грамотний фахівець. Анно, спасибі!",
            "Ми відкриті":"Ми відкриті",
            "Пн - Пт: 09:00-18:00":"Пн - Пт: 09:00-18:00",
            "Назва компанії":"Назва компанії",
            "Центральний офіс Пушкінська вулиця, 32":"Центральний офіс Пушкінська вулиця, 32",
            "Філія 1 , Пушкінська 32":"Філія 1 , Пушкінська 32",
            "Філія 4, Французький бульвар 2":"Філія 4, Французький бульвар 2",
            "Філія 5, Генуезька 24Б":"Філія 5, Генуезька 24Б",
            "Філія 12, Семена Палія, 103":"Філія 12, Семена Палія, 103",
            "Філія 14, Пушкінська 32":"Філія 14, Пушкінська 32",
            "Філія 17, Б. Арнаутська 23б":"Філія 17, Б. Арнаутська 23б",
            "Дізнатись детальніше":"Дізнатись детальніше",
            "Призначення":"Призначення",
            "Занадто багато запитів спробуйте пізніше":"Занадто багато запитів спробуйте пізніше",
            "Щось пішло не так":"Щось пішло не так",
            "Ні":"Ні",


        }
    },
    en: {
        translation: {
            "Ласкаво просимо до React": "Welcome to React and react-i18next",
            "Придбати": "Buy",
            "працівників":"employees",
            "щасливих клієнтів":"happy customers",
            "Ми поважаємо індивідуальність вибору кожного клієнта, адже цілі наших клієнтів - наші цілі! Ми робимо все, щоб досягти їх. І робимо це не тому що мусимо, а тому що хочемо це робити. Ґрунтуючись на принципах нашої роботи - ясності, чесності та прозорості - ми гарантуємо високий рівень використовуваних схем і стандартів, а також професіоналізм і відповідальність усіх співробітників компанії.":"We respect the individuality of each client's choice, because our clients' goals are our goals! We do our best to achieve them. And we do it not because we have to, but because we want to. Based on the principles of our work - clarity, honesty and transparency - we guarantee a high level of the schemes and standards used, as well as the professionalism and responsibility of all employees of the company.",
            "ХОЧУ СТАТИ ЧАСТИНОЮ КОМАНДИ":"I WANT TO BECOME PART OF THE TEAM",
            "Міжнародне агенство нерухомості":"International real estate agency",
            "Продати": "Sell",
            "Наші співробітники - професіонали. Вони використовують свої знання, досвід і аналітику ринку для того, щоб ви отримали максимальну вигоду з угоди, і при цьому безпечно і з дотриманням інтересів усіх сторін.":"Our employees are professionals. They use their knowledge, experience and market analytics to ensure that you get the most out of the transaction, while doing so safely and in a manner that respects the interests of all parties.",
            "Агентство нерухомості «Dominanta» створює можливості для реалізації бажань клієнтів на ринку нерухомості. Наші послуги є джерелом позитивних змін у житті наших клієнтів. Критерії нашої роботи - уявлення сучасної людини про першокласні послуги. Саме тому розвиток сервісу і кожного агента особисто - не просто гучна заява, а поступальна і копітка робота.":"Dominanta Real Estate Agency creates opportunities for clients to realize their real estate desires. Our services are a source of positive changes in the lives of our clients. The criteria for our work are the ideas of a modern person about first-class services. That is why the development of the service and each agent personally is not just a loud statement, but a progressive and painstaking work.",
            "в світ нерухомості":"to the world of real estate",
            "За цей час ми зробили щасливішими понад 10 тисяч клієнтів, а наша репутація стала гарантом виконання наших обов'язків та поставленого результату":"During this time, we have made more than 10 thousand clients happier, and our reputation has become a guarantor of the fulfillment of our duties and the results achieved.",
            "Ми займаємося підбором об\'єктів первинної та вторинної житлової нерухомості, а також комерційної нерухомості. З нами ви можете не лише купувати, а й продавати об\'єкти нерухомості":"We are engaged in the selection of primary and secondary residential real estate, as well as commercial real estate. You can not only buy but also sell real estate with us.",
            "Ми знаємо, як це складно. Дозвольте нам допомогти!":"We know how difficult it is. Let us help you!",
            "Заповніть заявку, і наш фахівець знайде ідеальну нерухомість для вас у потрібному районі":"Fill out the application and our specialist will find the perfect property for you in the right area",
            "Отримати добірку":"Get a selection",
            "Залиште питання про нерухомість":"Leave a question about real estate",
            "До кожного клієнта ми закріплюємо персонального агента з нерухомості, який буде з вами з моменту підбору об'єкта нерухомості до отримання ключів":"We assign a personal real estate agent to each client, who will be with you from the moment you select a property until you receive the keys.",
            "Зверталися до філії на Армійській з приводу купівлі комерційного приміщення, менеджерка Ганна швидко зорієнтувала по варіантах і в нашому ціновому сегменті. У підсумку зупинилися на БЦ, що стоїть на Краснова ...":"We contacted the branch on Armeyskaya about buying commercial premises, and the manager Anna quickly oriented us to the options in our price segment. As a result, we settled on a business center located on Krasnov ...",
            "Ми розуміємо, що кожен крок на шляху до вашої нової оселі може бути джерелом стресу та хвилювання. Але з нами все інакше":"We understand that every step of the way to your new home can be a source of stress and anxiety. But with us, it's different",
            "Розмістіть оголошення про продаж або дізнайтесь ринкову вартість нерухомості":"Place an ad for sale or find out the market value of real estate",
            "Оцінити": "Evaluate",
            "Гараж":"Garage",
            "У нас один з найкращих і великих каталогів, де ви зможете знайти потрібний вам об'єкт в залежності від вартості, локації та інших переваг":"We have one of the best and largest catalogs, where you can find the object you need depending on the cost, location and other preferences",
            "Ім'я":"Name",
            "Довго самі шукали підходящий варіант квартири в малоповерховому будинку, і неодноразово зверталися в агентство і не прогадали. Ріелтор швидко зорієнтував за наявністю об'єктів і в районі, що цікавить ...":"For a long time we were looking for a suitable apartment in a low-rise building, and we repeatedly contacted the agency and did not lose. The realtor quickly oriented us by the availability of objects and in the area of interest ...",
            "Все було професійно і якісно. Вона зробила все швидко і професійно. Була завжди поруч, допомагала і радила. Усім рекомендую: вона чудовий і грамотний фахівець. Анно, спасибі!":"Everything was professional and of high quality. She did everything quickly and professionally. She was always there to help and advise. I recommend her to everyone: she is a wonderful and competent specialist. Anna, thank you!",
            "Ми ретельно підбираємо і перевіряємо кожну пропозицію, щоб відповідати вашим очікуванням. Наші експерти забезпечать професійну фотосесію, створять привабливі оголошення та проведуть переговори з потенційними покупцями.":"We carefully select and check each offer to ensure that it meets your expectations. Our experts will provide a professional photo shoot, create attractive ads, and negotiate with potential buyers.",
            "Участки":"Areas",
            "Оленька Ковальска":"Olenka Kovalska",
            "Олена Казакова":"Elena Kazakova",
            "Лана Акімова":"Lana Akimova",
            "Ми в соціальних мережах":"We are on social networks",
            "Руслан Штарк":"Ruslan Shtark",
            "років роботи":"years of work",
            "філіалів по місту":"branches in the city",
            "спеціалістів по нерухомості":"real estate specialists",
            "Співпрацюючи з Dominanta, ви отримуєте не просто послугу - ви отримуєте впевненість і підтримку на кожному етапі. Наша команда досвідчених фахівців візьме на себе всі турботи: від первинної консультації до підписання договору":"Cooperating with Dominanta, you get more than just a service - you get confidence and support at every stage. Our team of experienced professionals will take care of everything: from the initial consultation to the signing of the contract",
            "Дозвольте нам перетворити ваші мрії на реальність. З Dominanta ви завжди в центрі уваги":"Let us turn your dreams into reality. With Dominanta, you are always in the center of attention",
            "Зв'яжіться з нами і дізнайтесь, як ми можемо допомогти вам продати вашу нерухомість швидко і вигідно!":"Contact us and find out how we can help you sell your property quickly and profitably!",
            "Про компанію": "About the company",
            "У Dominanta ми віримо, що довіра - це ключ до успішної співпраці. Ми пишаємося нашою репутацією і прагнемо до того, щоб кожен клієнт почувався в безпеці та знав, що його нерухомість у надійних руках. Ваша мрія про новий будинок - наша головна мета":"At Dominanta, we believe that trust is the key to successful cooperation. We are proud of our reputation and strive to make every client feel safe and know that their property is in good hands. Your dream of a new home is our main goal",
            "Новини": "News",
            "Контакти": "Contacts",
            "Як це працює":"How does it work",
            "Квартиры вторичка":"Resale property",
            "Допоможемо продати нерухомість швидко і вигідно":"We will help you sell real estate quickly and profitably",

            "Добрий день. Хочу продати нерухомість.\nПотрібна консультація":"Good afternoon. I want to sell my real estate.\nI need a consultation",
            "Тип нерухомості":"Estate type",
            "Квартира":"Room",
            "Таунхаус":"Townhouse",
            "Отримати безкоштовну консультацію":"Get a free consultation",
            "Коментар":"Comment",
            "Квартири вторинний ринок":"Resale property",
            "Квартири втор. ринок":"Resale property",
            "Квартири біля моря":"Apartments near the sea",
            "Квартири з терасами":"Apartments with terraces",
            "1-кімнатні":"1-room",
            "2-х кімнатні":"2-rooms",
            "3-х кімнатні":"3 rooms",
            "Новобудова":"New building",
            "Швидкий продаж":"Quick sale",
            "Юридична безпека":"Legal security",
            "Висока ціна":"High price",
            "Персональний менеджер":"Personal manager",

            "Дом":"Houses",
            "Будинок":"House",
            "Ділянка":"Area",
            "13 червня 2024":"June 13, 2024",
            "15 квітня 2024":"April 15, 2024",
            "16 грудня 2023":"December 16, 2023",
            "9 жовтня 2023":"October 9, 2023",
            "Розмістіть оголошення":"Place an ad",
            "Підготовка до продажу":"Preparing for the sale",
            "Пошук покупців":"Search for customers",
            "Укладання договору":"Conclusion of the agreement",
            "Коммерция":"Commerce",
            "Новострой":"New building",
            "Новобудови":"New building",
            "Здані ЖК":"Housing complex is completed",
            "Будуються ЖК":"Housing complex under construction",
            "З готовим ремонтом":"Ready repair",
            "Новобудова на виплату":"New buildings on payments",
            "Акційні пропозиції":"Promotional offers",
            "Новобудови в Аркадії":"New buildings in Arcadia",
            "Новобудови в Центрі":"New buildings in the Center",
            "Новобудови у Київському районі":"New buildings in Kyiv district",
            "Новобудови у Суворівському районі":"New buildings in Suvorivsky district",
            "Новобудови у Малинівському районі":"New buildings in the Malinovsky district",
            "Будинки та Дачі":"Houses and Cottages",
            "Таунхауси":"Townhouses",
            "Ділянки біля моря":"Areas near the sea",
            "Ділянки в межах міста":"Areas within the city limits",
            "Ділянки за містом":"Areas outside the city",
            "Комерція":"Commerce",
            "Каталог комерції":"Catalog of commerce",
            "Офісні приміщення":"Office premises",
            "Приміщення вільного призначення":"Premises for free use",
            "Приміщення промислового призначення":"Premises for industrial use",
            "Виробничі приміщення":"Production premises",
            "Магазини, салони":"Shops, salons",
            "Ресторани, кафе, бари":"Restaurants, cafes, bars",
            "Склади, ангари":"Warehouses, hangars",
            "Паркінги":"Parking lots",
            "вся нерухомість Одеси":"all real estate in Odessa",
            "безпечний пошук нерухомості в Одесі":"safe search for real estate in Odesa",
            "Дванадцять років на одеському ринку нерухомості":"Twelve years on the Odessa real estate market",
            "Допомога експерта":"Help from an expert",
            "Отримайте п'ять пропозицій під ваш запит":"Get five offers for your request",
            "Будинки":"Houses",
            "Ділянки":"Areas",
            "Виберіть місто":"Choose city",
            "Виберіть район":"Choose an area",
            "Ціна":"Price",
            "Мін. ціна":"Min. price",
            "Макс. ціна":"Max. price",
            "Кількість кімнат":"Count of rooms",
            "Пошук":"Search",
            "Розширений пошук":"Advanced Search",

            "Популярні запити":"Populars",
            "Загальна площа":"Total area",
            "Житлова площа":"Living space",
            "Поверх":"Floor",
            "поверх":"floor",
            "поверховий":"floors",
            "поверхи":"floors",
            "Кількість поверхів":"Number of storeys",
            "Площа кухні":"Kitchen area",
            "Тип будівлі":"Building type",
            "Опалення":"Heating",
            "Стіни":"Walls",
            "Ремонт":"Renovation",
            "Очистити фільтри":"Clear filters",
            "Показати":"Show",
            "пропозиції":"proposals",
            "Дивитись всі об'єкти":"See all objects",

            "Найкращі":"The best",
            "На ринку нерухомості Одеси":"On the Odessa real estate market",
            "Усі пропозиції":"All offers",
            "Продаж та купівля нерухомості з АН Домінанта - це спосіб швидко і просто здійснити угоду":"Selling and buying real estate with AN Dominant is a quick and easy way to make a deal",
            "Ріелтор агентства зробить всю роботу за вас і супроводжує на кожному етапі, аж до завершального. Тільки так можна отримати задоволеного клієнта":"The realtor of the agency will do all the work for you and will accompany you at every stage, up to the final one. This is the only way to get a satisfied customer",
            "Докладніше про нас":"More about us",
            "фахівців":"specialists",
            "з нерухомості":"by real estate",
            "об'єктів у базі":"the objects in the database",
            "новобудов у базі":"new buildings in the database",
            "угод за рік":"contracts per year",
            "Агентство нерухомості Домінанта працює дванадцять років на одеському ринку":"The Dominanta real estate agency has been operating on the Odessa market for twelve years",
            "11 філій по всьому місту, професійні співробітники, які в найкоротші терміни підберуть Вам найкраще вирішення квартирного питання":"11 offices throughout the city, the most professional employees who will find the best solution for your housing problem in the shortest possible time",
            "Отримати консультацію":"Get a consultation",
            "Як відбувається співпраця":"How does cooperation work",
            "Заявка":"Application",
            "Ми цінуємо кожного нашого клієнта та прагнемо надати лише найвищий рівень сервісу. Якість та професіоналізм – це основні принципи, якими ми керуємося в роботі. Тому, при зверненні до нас, ви можете бути впевнені, що вам буде надано досвідченого експерта, який спеціалізується саме на вашому типі нерухомості":"We value each of our clients and strive to provide only the highest level of service. Quality and professionalism are the main principles that guide our work. Therefore, when contacting us, you can be sure that you will be provided with an experienced expert who specializes specifically in your type of property",
            "Консультація":"Consultation",
            "Завдяки унікальній базі об'єктів та передовим цифровим технологіям. Ми надаємо клієнтам найбільш релевантну добірку об'єктів, які найкраще відповідають їхнім запитам та потребам":"Thanks to a unique database of objects and advanced digital technologies. We provide clients with the most relevant selection of properties that best suit their needs and requirements.",
            "Огляд":"Inspection",
            "Ми цінуємо ваш час та комфорт, тому зробимо все можливе, щоб ваше знайомство з нерухомістю було приємним та результативним. Наші тури – це незабутній досвід, який дозволить вам поринути у атмосферу обраного району. Наш агент покаже вам всі цікаві місця, розповість про нерухомість, її історію та архітектуру. Ви дізнаєтеся про інфраструктуру району та інші особливості, які допоможуть вам зробити правильний вибір":"We value your time and comfort, so we will do our best to make your acquaintance with real estate pleasant and productive. Our tours are an unforgettable experience that will allow you to experience the atmosphere of the chosen area. Our agent will show you all the interesting places, tell you about the property, its history and architecture. You will learn about the infrastructure of the area and other features that will help you make the right choice",
            "Договір":"Contract",
            "Ми готові надати вам якісний супровід під час придбання нерухомості. Наші досвідчені юристи мають високу кваліфікацію та професійний підхід до роботи. Вони проведуть юридичне обстеження об'єкта нерухомості та оцінять усі юридичні та фінансові ризики, пов'язані з угодою":"We are ready to provide you with high-quality support in the acquisition of real estate. Our experienced lawyers are highly qualified and have a professional approach to their work. They will conduct a legal due diligence of the property and assess all legal and financial risks associated with the transaction",
            "Чому з Dominanta вигідно співпрацювати":"Why it is profitable to cooperate with Dominanta",
            "Широкий спектр послуг":"Wide range of services",
            "Найкраща база об'єктів":"The best database of objects",
            "Більш ніж 10-річний досвід":"More than 10 years of experience",
            "Індивідуальний підхід до кожного":"Individual approach to everyone",
            "Новобудови без комісії":"New buildings without commission",
            "Домінанта офіційний партнер найкращих новобудов міста Одеса. Купуйте з нами та не платіть комісію":"The Dominanta is the official partner of the best new buildings in the city of Odessa. Buy with us and pay no commission",
            "Комісія":"Commission",
            "Послуги":"Services",
            "Про нас":"About Us",
            "Вакансії":"Jobs",
            "Наші проєкти":"Our projects",
            "Бонус":"Bonus",
            "Всі права захищені":"All rights reserved",
            "Завантажити":"Download",
            "Застосунок":"Application",
            "Вгору":"Up",
            "Головна":"Home",
            "Каталог об'єктів":"Catalog",
            "Одеса":"Odesa",
            "результатів":"results",
            "Слідкуйте за новими пропозиціями – підписуйтесь на наші канали.":"Stay tuned for new offers - subscribe to our channels.",
            "Фільтри":"Filters",
            "Не вдається самостійно знайти нерухомість?":"Can't find a property on your own?",
            "Дайте відповідь на пару простих питань":"Could you answer a couple of simple questions",
            "і наш агент зробить усю роботу за вас!":"and our agent will do all the work for you!",
            "Відповісти на питання":"Answer the questions",
            "Ще фільтри":"More filters",
            "Район":"District",
            "Пошук за ID":"Search by ID",
            "Приховати":"Hide",
            "Вартість":"Price",
            "В продажі":"On sale",
            "кімнати":"rooms",
            "кімната":"room",
            "поверх з":"floor from",
            "Детальніше":"More",
            "Опис":"Description",
            "Характеристики":"Characteristics",
            "Розташування":"Location",
            "Основні":"Main",
            "Код об'єкта":"Object ID",
            "Область":"Region",
            "Населений пункт":"Locality",
            "Про квартиру":"About the apartment",
            "Про комерцію":"About commerce",
            "Кімнатність":"Count of rooms",
            "Висота стелі":"Ceiling height",
            "Санвузол":"Bathroom",
            "Балкон":"Balcony",
            "Дача":"Cottage",
            "Стан балкону":"Balcony condition",
            "Вікна":"Window",
            "Сторони світу":"World sides",
            "Вид":"View",
            "Чому вибирають нас":"Why us",
            "Гарантії та переваги роботи з нашим агенством":"Guarantees and benefits of working with our agency",
            "Тераса":"Terrace",
            "Про будинок":"About the building",
            "Матеріал будівництва":"Building material",
            "Двір":"Yard",
            "Адреса":"Address",
            "Ріелтор об'єкту":"Property realtor",
            "Записати на перегляд":"Book for viewing",
            "Схожі об'єкти":"Similar objects",
            "Кільк. кімнат":"Count rooms",
            "Замовити дзвінок":"Order a call",
            "Ваше ім'я": "Your Name",
            "Ознайомлений з": "Acquainted with",
            "Політикою конфіденційності": "Privacy Policy",
            "та даю згоду на збір та обробку своїх персональних даних": "and I consent to the collection and processing of my personal data",
            "Дякуємо":"Thank you",
            "Ми зв'яжемося з вами найближчим часом":"We will contact you shortly",
            "Партнери, якими ми пишаемось":"Partners we are proud of",
            "96% клієнтів задоволені нашими послугами":"96% of clients are satisfied with our services",
            "З компанією співпрацюють понад 300 експертів у сфері нерухомості, які безперервно разом із Dominanta, проходять навчання для надання якісних послуг. А після угоди – клієнти оцінюють їхню роботу":"More than 300 real estate experts cooperate with the company, who continuously, together with Dominanta, undergo training to provide quality services. And after the transaction, clients evaluate their work",
            "Залишити відгук":'Leave feedback',
            "Залиште свій номер телефону, і наш фахівець зв'яжеться з вами. Він поставить кілька запитань про вашу нерухомість і розмістить ваше оголошення на сайті":"Leave your phone number and our specialist will contact you. He will ask a few questions about your property and place your ad on the website",
            "Спеціалізація":"Specialization",
            "Досвід роботи":"Work experience",
            "Ми розмістимо ваше оголошення на популярних міських платформах для пошуку нерухомості. Наш фахівець з нерухомості організує особисті або онлайн покази вашого об'єкта для потенційних покупців.":"We will place your ad on popular city real estate search platforms. Our real estate specialist will organize personal or online showings of your property for potential buyers.",
            "Сегмент":"Segment",
            "Кількість угод":"Deals",
            "Новобуди та вторинний ринок":"New build and resale properties",
            "16 років":"Over 16 years",
            "Середній":"Average",
            "Понад 200":"Over 200",
            "Вторинний ринок нерухомості":"Resale properties",
            "14 років":"Over 14 years",
            "Вищий за середній":"Higher than average",
            "Понад 500":"Over 500",
            "Ми гарантуємо швидкий продаж вашої нерухомості завдяки нашій великій базі покупців та ефективній маркетинговій стратегії":"We guarantee a quick sale of your property thanks to our large buyer base and effective marketing strategy",
            "Ми забезпечимо повний юридичний супровід угоди, захищаючи ваші інтереси на кожному етапі":"We will provide full legal support for the transaction, protecting your interests at every stage",
            "Ми допоможемо вам отримати максимальну вартість за вашу нерухомість, використовуючи точну оцінку ринку та переговорні навички":"We will help you to get the maximum value for your property using accurate market assessment and negotiation skills",
            "За вами буде закріплено персонального менеджера, котрий буде курирувати весь процес продажу і відповідати на всі ваші запитання":"You will be assigned a personal manager who will oversee the entire sales process and answer all your questions",
            "Новобудова та вторинний ринок, комерція":"New build, resale properties, commerce",
            "8 років":"Over 8 years",
            "Елітна нерухомість":"Elite real estate",
            "Понад 450":"Over 450",
            "Ми додамо фотографії та планування, а також створимо привабливий опис вашої пропозиції. Ви можете підписати договір з агентом в офісі або онлайн":"We will add photos and planning, as well as create an attractive description of your offer. You can sign a contract with an agent in the office or online",
            "Ріелторські послуги при купівлі та продажу":"Real estate services for buying and selling",
            "Відгуки":"Reviews",
            "Мої об'єкти":"My objects",
            "Залишіть Ваш номер телефону та Вам передзвонять протягом 5 хвилин":"Leave your phone number and we will call you back within 5 minutes",
            "Ваш відгук":'Your Feedback',
            "Надіслати": "Send",
            "ЖК Кімолос":"RC Kimolos",
            "18-поверховий проєкт бізнес класу в приморському районі з панорамним видом на море, парки та пляжі Аркадії": "18-storey business class project in the seaside area with panoramic views of the sea, parks and beaches of Arcadia",
            "ЖК Breeze Marine": "RC Breeze Marine",
            "Для короткострокової або довгострокової оренди. Проєкт апарт-комплексів на узбережжі Чорного моря. Басейн і зона відпочинку на даху":"For short-term or long-term rent. Project of apartment complexes on the Black Sea coast. Swimming pool and recreation area on the roof",
            "ЖК Modern":"RC Modern",
            "Багатосекційний проєкт на 6 станції Фонтана. Нестандартний дизайн фасадів, внутрішній простір для мешканців, просторе лобі та консьєрж-сервіс":"A multi-section project at Fontana station 6. Non-standard design of facades, interior space for residents, spacious lobby and concierge service",
            "Так":"Yes",
            "Тип санвузла":"Type of bathroom",
            "Тип вікон":"Windows",
            "Каталог будинків":"Catalog of houses",
            "Таунхауз":"Townhouse",
            "Dominanta - твій ключ":"Dominanta - your key",
            "Дізнатись детальніше":"Learn more",
            "Форма ділянки":"Land plot form",
            "Агенція нерухомості «Dominanta» працює на ринку нерухомості вже 12+ років і має експертизу в галузі купівлі та продажу житлової та комерційної нерухомості. З 2023 року агентство входить до структури Dominanta corporation.":"Dominanta Real Estate Agency has been operating in the real estate market for 12+ years and has expertise in buying and selling residential and commercial real estate. Since 2023, the agency has been a part of Dominanta Corporation.",
            "Про ділянку":"About Land plot",
            "Dominanta - це":"Dominanta - is",
            "Площа ділянки":"Land plot area",
            "Місце знаходження":"Location",
            "Комунікації":"Communications",
            "сотки":"acres of land.",
            "З компанією співпрацюють понад 300 експертів у сфері нерухомості, які безперервно разом з Dominanta, проходять навчання для надання якісних послуг, а після угоди - клієнти оцінюють їхню роботу":"The company employs more than 300 real estate experts who are continuously trained with Dominanta to provide quality services, and after the transaction, clients evaluate their work",
            "Квартири":"Real estate",
            "Каталог ділянок":"Catalog of plots",
            "Беремо на себе весь процес підготовки документів і проведення угоди. Організовуємо і супроводжуємо процес передачі завдатку, можна онлайн. Оплачуєте послуги компанії. Проводимо операцію, реєструємо право власності. Передаєте ключі.":"We take care of the entire process of preparing documents and conducting the transaction. We organize and accompany the process of transferring the deposit, which can be done online. You pay for the company's services. We conduct the transaction and register the ownership. You hand over the keys.",
            "ділянка":"land of plot",
            "Вибрані":"Favorites",
            "Надихаємо довіру, створюємо затишок.":"We inspire trust and create comfort.",
            "Вибрані об'єкти":"My favorites",
            "Найкрутіше агенство нерухомості! Дякую за ваш ПРОФЕСІОНАЛІЗМ!!! Процвітання вам! Побільше вдячних клієнтів! Побільше угод!":"The coolest real estate agency! Thank you for your PROFESSIONALISM!!! Prosperity to you! More grateful customers! More deals!",
            "Ми відкриті":"Working time",
            "Пн - Пт: 09:00-18:00":"Mon - Fri: 09:00-18:00",
            "Назва компанії":"Company name",
            "Ваше повідомлення":"Your message",
            "Центральний офіс Пушкінська вулиця, 32":"Main office, Pushkinska Street, 32",
            "Філія 1 , Пушкінська 32":"Office 1, Pushkinska 32",
            "Філія 4, Французький бульвар 2":"Office 4, French Boulevard 2",
            "Філія 5, Генуезька 24Б":"Office 5, Genouezka 24B",
            "Філія 12, Семена Палія, 103":"Office 12, Semena Palia, 103",
            "Філія 14, Пушкінська 32":"Office 14, Pushkinska 32",
            "Філія 17, Б. Арнаутська 23б":"Office 17, B. Arnautska 23b",
            "Призначення":"Purposing",
            "Занадто багато запитів спробуйте пізніше":"Too many requests try again later",
            "Щось пішло не так":"Something went wrong",
            "Ні":"No",
        }
    },

    ru: {
        translation: {
            "Ласкаво просимо до React": "Добро пожаловать в Реагировать",
            "Придбати": "Купить",
            "щасливих клієнтів":"счастливых клиентов",
            "Продати": "Продать",
            "Агентство нерухомості «Dominanta» створює можливості для реалізації бажань клієнтів на ринку нерухомості. Наші послуги є джерелом позитивних змін у житті наших клієнтів. Критерії нашої роботи - уявлення сучасної людини про першокласні послуги. Саме тому розвиток сервісу і кожного агента особисто - не просто гучна заява, а поступальна і копітка робота.":"Агентство недвижимости «Dominanta» создает возможности для реализации желаний клиентов на рынке недвижимости. Наши услуги являются источником позитивных изменений в жизни наших клиентов. Критерии нашей работы - представление современного человека о первоклассных услугах. Именно поэтому развитие сервиса и каждого агента лично - не просто громкое заявление, а поступательная и кропотливая работа.",
            "Гараж":"Гараж",
            "працівників":"сотрудников",
            "Ім'я":"Имя",
            "в світ нерухомості":"в мир недвижимости",
            "Ми поважаємо індивідуальність вибору кожного клієнта, адже цілі наших клієнтів - наші цілі! Ми робимо все, щоб досягти їх. І робимо це не тому що мусимо, а тому що хочемо це робити. Ґрунтуючись на принципах нашої роботи - ясності, чесності та прозорості - ми гарантуємо високий рівень використовуваних схем і стандартів, а також професіоналізм і відповідальність усіх співробітників компанії.":"Мы уважаем индивидуальность выбора каждого клиента, ведь цели наших клиентов – наши цели! Мы делаем все, чтобы достичь их. И делаем это не потому что должны, а потому что хотим это делать. Основываясь на принципах нашей работы – ясности, честности и прозрачности – мы гарантируем высокий уровень используемых схем и стандартов, а также профессионализм и ответственность всех сотрудников компании.",
            "Міжнародне агенство нерухомості":"Международное агентство недвижимости",
            "Ваше повідомлення":"Ваше сообщение",
            "Dominanta - це":"Dominanta - это",
            "Ми в соціальних мережах":"Мы в социальных сетях",
            "Оленька Ковальска":"Оленька Ковальска",
            "Олена Казакова":"Елена Казакова",
            "Ми знаємо, як це складно. Дозвольте нам допомогти!":"Мы знаем, как это сложно. Позвольте нам помочь!",
            "Заповніть заявку, і наш фахівець знайде ідеальну нерухомість для вас у потрібному районі":"Заполните заявку, и наш специалист найдет идеальную недвижимость для вас в нужном районе",
            "Лана Акімова":"Лана Акимова",
            "Отримати добірку":"Получить подборку",
            "Руслан Штарк":"Руслан Штарк",
            "До кожного клієнта ми закріплюємо персонального агента з нерухомості, який буде з вами з моменту підбору об'єкта нерухомості до отримання ключів":"К каждому клиенту мы закрепляем персонального агента по недвижимости, который будет с вами с момента подбора объекта недвижимости до получения ключей.",
            "За цей час ми зробили щасливішими понад 10 тисяч клієнтів, а наша репутація стала гарантом виконання наших обов'язків та поставленого результату":"За это время мы сделали счастливее более 10 тысяч клиентов, а наша репутация стала гарантом выполнения наших обязанностей и поставленного результата.",
            "У нас один з найкращих і великих каталогів, де ви зможете знайти потрібний вам об'єкт в залежності від вартості, локації та інших переваг":"У нас один из лучших и обширных каталогов, где вы сможете найти нужный вам объект в зависимости от стоимости, локации и других предпочтений.",
            "Ми займаємося підбором об\'єктів первинної та вторинної житлової нерухомості, а також комерційної нерухомості. З нами ви можете не лише купувати, а й продавати об\'єкти нерухомості":"Мы занимаемся подбором объектов первичной и вторичной жилой недвижимости, а а также коммерческой недвижимостью. С нами вы можете не только покупать, но и продавать объекты недвижимости",
            "Довго самі шукали підходящий варіант квартири в малоповерховому будинку, і неодноразово зверталися в агентство і не прогадали. Ріелтор швидко зорієнтував за наявністю об'єктів і в районі, що цікавить ...":"Долго сами искали подходящий вариант квартиры в малоэтажном доме, и неоднократно обращались в агентство и не прогадали. Риэлтор быстро сориентировал по наличию объектов и в интересующем районе...",
            "Таунхаус":"Таунхаус",
            "Ми ретельно підбираємо і перевіряємо кожну пропозицію, щоб відповідати вашим очікуванням. Наші експерти забезпечать професійну фотосесію, створять привабливі оголошення та проведуть переговори з потенційними покупцями.":"Мы тщательно подбираем и проверяем каждое предложение, чтобы соответствовать вашим ожиданиям. Наши эксперты обеспечат профессиональную фотосессию, создадут привлекательные объявления и проведут переговоры с потенциальными покупателями.",
            "Співпрацюючи з Dominanta, ви отримуєте не просто послугу - ви отримуєте впевненість і підтримку на кожному етапі. Наша команда досвідчених фахівців візьме на себе всі турботи: від первинної консультації до підписання договору":"Сотрудничая с Dominanta, вы получаете не просто услугу - вы получаете уверенность и поддержку на каждом этапе. Наша команда опытных специалистов возьмет на себя все заботы: от первичной консультации до подписания договора",
            "Ми розуміємо, що кожен крок на шляху до вашої нової оселі може бути джерелом стресу та хвилювання. Але з нами все інакше":"Мы понимаем, что каждый шаг на пути к вашему новому дому может быть источником стресса и волнения. Но с нами все иначе",
            "Як це працює":"Как это работает",
            "Отримати безкоштовну консультацію":"Получить бесплатную консультацию",
            "Оцінити": "Оценить",
            "Зв'яжіться з нами і дізнайтесь, як ми можемо допомогти вам продати вашу нерухомість швидко і вигідно!":"Свяжитесь с нами и узнайте, как мы можем помочь вам продать вашу недвижимость быстро и выгодно!",
            "Участки":"Участки",
            "Зверталися до філії на Армійській з приводу купівлі комерційного приміщення, менеджерка Ганна швидко зорієнтувала по варіантах і в нашому ціновому сегменті. У підсумку зупинилися на БЦ, що стоїть на Краснова ...":"Обращались в филиал на Армейской по поводу покупки коммерческого помещения, менеджер Анна быстро сориентировала по вариантам и в нашем ценовом сегменте. В итоге остановились на стоящемся БЦ на Краснова ...",
            "Ми забезпечимо повний юридичний супровід угоди, захищаючи ваші інтереси на кожному етапі":"Мы обеспечим полное юридическое сопровождение сделки, защищая ваши интересы на каждом этапе",
            "Ми допоможемо вам отримати максимальну вартість за вашу нерухомість, використовуючи точну оцінку ринку та переговорні навички":"Мы поможем вам получить максимальную стоимость за вашу недвижимость, используя точную оценку рынка и переговорные навыки",
            "За вами буде закріплено персонального менеджера, котрий буде курирувати весь процес продажу і відповідати на всі ваші запитання":"За вами будет закреплен персональный менеджер, который будет курировать весь процесс продажи и отвечать на все ваши вопросы",
            "Розмістіть оголошення про продаж або дізнайтесь ринкову вартість нерухомості":"Разместите объявление о продаже или узнайте рыночную стоимость недвижимости",
            "Добрий день. Хочу продати нерухомість.\nПотрібна консультація":"Добрый день. Хочу продать недвижимость.\nНужна консультация",
            "Тип нерухомості":"Тип недвижимости",
            "Дозвольте нам перетворити ваші мрії на реальність. З Dominanta ви завжди в центрі уваги":"Позвольте нам превратить ваши мечты в реальность. С Dominanta вы всегда в центре внимания",
            "Квартира":"Квартира",
            "У Dominanta ми віримо, що довіра - це ключ до успішної співпраці. Ми пишаємося нашою репутацією і прагнемо до того, щоб кожен клієнт почувався в безпеці та знав, що його нерухомість у надійних руках. Ваша мрія про новий будинок - наша головна мета":"В Dominanta мы верим, что доверие - это ключ к успешному сотрудничеству. Мы гордимся нашей репутацией и стремимся к тому, чтобы каждый клиент чувствовал себя в безопасности и знал, что его недвижимость в надежных руках. Ваша мечта о новом доме - наша главная цель",
            "Агентство нерухомості Домінанта працює дванадцять років на одеському ринку":"Агентство недвижимости Доминанта работает двенадцать лет на одесском рынке",
            "Коментар":"Комментарий",
            "Про компанію": "О компании",
            "Новини": "Новости",
            "Контакти": "Контакты",
            "Найкрутіше агенство нерухомості! Дякую за ваш ПРОФЕСІОНАЛІЗМ!!! Процвітання вам! Побільше вдячних клієнтів! Побільше угод!":"Самое крутое агенство недвижимости! Спасибо за ваш ПРОФЕССИОНАЛИЗМ!!!! Процветания вам! Побольше благодарных клиентов! Побольше сделок!",
            "Все було професійно і якісно. Вона зробила все швидко і професійно. Була завжди поруч, допомагала і радила. Усім рекомендую: вона чудовий і грамотний фахівець. Анно, спасибі!":"Все было профессионально и качественно. Она сделала все быстро и профессионально. Была всегда рядом, помогала и советовала. Всем рекомендую: она замечательный и грамотный специалист. Анна, спасибо!",
            "Надихаємо довіру, створюємо затишок.":"Вдохновляем доверие, создаем уют.",
            "Квартири вторинний ринок":"Квартиры вторичка",
            "Квартиры вторичка":"Квартиры вторичка",
            "Квартири втор. ринок":"Квартиры вторичка",
            "Квартири біля моря":"Квартиры у моря",
            "Квартири з терасами":"Квартиры с террасами",
            "Наші співробітники - професіонали. Вони використовують свої знання, досвід і аналітику ринку для того, щоб ви отримали максимальну вигоду з угоди, і при цьому безпечно і з дотриманням інтересів усіх сторін.":"Наши сотрудники – профессионалы. Они используют свои знания, опыт и аналитику рынка для того, чтобы вы получили максимальную выгоду из сделки, и при этом безопасно и с соблюдением интересов всех сторон.",
            "1-кімнатні":"1-комнатные",
            "2-х кімнатні":"2-х комнатные",
            "3-х кімнатні":"3-х комнатные",
            "Новобудова":"Новострой",
            "Розмістіть оголошення":"Разместите объявление",
            "Підготовка до продажу":"Подготовка к продаже",
            "Пошук покупців":"Поиск покупателей",
            "Укладання договору":"Заключение сделки",
            "Швидкий продаж":"Быстрая продажа",
            "Юридична безпека":"Юридическая безопасность",
            "Висока ціна":"Высокая цена",
            "Персональний менеджер":"Персональный менеджер",

            "Ми гарантуємо швидкий продаж вашої нерухомості завдяки нашій великій базі покупців та ефективній маркетинговій стратегії":"Мы гарантируем быструю продажу вашей недвижимости благодаря нашей обширной базе покупателей и эффективной маркетинговой стратегии.",
            "Новобудови":"Новострои",
            "Здані ЖК":"Сданные ЖК",
            "Будуються ЖК":"Строящиеся ЖК",
            "З готовим ремонтом":"С готовым ремонтом",
            "Новобудова на виплату":"Новострой в рассрочку",
            "Акційні пропозиції":"Акционные предложения",
            "Новобудови в Аркадії":"Новострои в Аркадии",
            "Новобудови в Центрі":"Новострои в Центре",
            "Новобудови у Київському районі":"Новострои в Киевском районе",
            "Новобудови у Суворівському районі":"Новострои в Суворовсом районе",
            "Новобудови у Малинівському районі":"Новострои в Малиновском районе",
            "Будинки та Дачі":"Дома и Дачи",
            "Залиште питання про нерухомість":"Оставьте вопрос о недвижимости",
            "13 червня 2024":"13 июня 2024",
            "15 квітня 2024":"15 апреля 2024",
            "16 грудня 2023":"16 декабря 2023",
            "9 жовтня 2023":"9 октября 2023",
            "Дом":"Дом",
            "Будинок":"Дом",
            "Ділянка":"Участок",
            "Таунхауси":"Танхаусы",
            "років роботи":"лет работы",
            "Агенція нерухомості «Dominanta» працює на ринку нерухомості вже 12+ років і має експертизу в галузі купівлі та продажу житлової та комерційної нерухомості. З 2023 року агентство входить до структури Dominanta corporation.":"Агентство недвижимости «Dominanta» работает на рынке недвижимости уже 12+ лет и имеет экспертизу в области покупки и продажи жилой и коммерческой недвижимости. С 2023 года агентство входит в структуру Dominanta corporation.",
            "філіалів по місту":"филиалов по городу",
            "спеціалістів по нерухомості":"специалистов по недвижимости",
            "Ділянки біля моря":"Участки у моря",
            "Ділянки в межах міста":"Участки в черте города",
            "Ділянки за містом":"Участки за городом",
            "Комерція":"Коммерция",
            "Каталог комерції":"Каталог коммерции",
            "Офісні приміщення":"Офисные помещения",
            "Приміщення вільного призначення":"Помещения свободного назначения",
            "Виробничі приміщення":"Производственные помещения",
            "Приміщення промислового призначення":"Помещения промышленного назначения",
            "Магазини, салони":"Магазины, салоны",
            "З компанією співпрацюють понад 300 експертів у сфері нерухомості, які безперервно разом з Dominanta, проходять навчання для надання якісних послуг, а після угоди - клієнти оцінюють їхню роботу":"С компанией сотрудничают более 300 экспертов в сфере недвижимости, которые непрерывно вместе с Dominanta, проходят обучение для предоставление качественных услуг а после сделки - клиенты оценивают их работу",
            "Ресторани, кафе, бари":"Рестораны, кафе, бары",
            "Склади, ангари":"Склады, ангары",
            "Паркінги":"Паркинги",
            "вся нерухомість Одеси":"вся недвижимость Одессы",
            "безпечний пошук нерухомості в Одесі":"безопасный поиск недвижимости в Одессе",
            "Дванадцять років на одеському ринку нерухомості":"Двенадцать лет на одесском рынке недвижимости",
            "Допомога експерта":"Помощь эксперта",
            "Отримайте п'ять пропозицій під ваш запит":"Получи пять предложений под ваш запрос",
            "Будинки":"Дома",
            "Ділянки":"Участки",
            "Коммерция":"Коммерция",
            "Новострой":"Новострой",
            "Виберіть місто":"Выберите город",
            "Виберіть район":"Выберите район",
            "Ціна":"Цена",
            "Мін. ціна":"Мин. цена",
            "Макс. ціна":"Маx. цена",
            "Кількість кімнат":"Количество комнат",
            "Пошук":"Поиск",
            "Розширений пошук":"Расширенный поиск",
            "Допоможемо продати нерухомість швидко і вигідно":"Поможем продать недвижимость быстро и выгодно",
            "Ми додамо фотографії та планування, а також створимо привабливий опис вашої пропозиції. Ви можете підписати договір з агентом в офісі або онлайн":"Мы добавим фотографии и планировку, а также создадим привлекательное описание вашего предложения. Вы можете подписать агентский договор в офисе или онлайн",
            "Популярні запити":"Популярные запросы",
            "Загальна площа":"Общая площадь",
            "Житлова площа":"Жилая площадь",
            "Беремо на себе весь процес підготовки документів і проведення угоди. Організовуємо і супроводжуємо процес передачі завдатку, можна онлайн. Оплачуєте послуги компанії. Проводимо операцію, реєструємо право власності. Передаєте ключі.":"Берем на себя весь процесс подготовки документов и проведения сделки. Организовываем и сопровождаем процесс передачи задатка, можно онлайн. Оплачиваете услуги компании. Проводим сделку, регистрируем право собственности. Передаете ключи.",
            "Поверх":"Этаж",
            "поверх":"этаж",
            "поверховий":"этажный",
            "поверхи":"этажы",
            "Кількість поверхів":"Этажность",
            "Площа кухні":"Площадь кухни",
            "Тип будівлі":"Тип здания",
            "Опалення":"Отопление",
            "Стіни":"Стены",
            "Ремонт":"Ремонт",
            "Очистити фільтри":"Очистить фильтры",
            "Показати":"Показать",
            "пропозиції":"предложений",
            "Дивитись всі об'єкти":"Смотреть все объекты",

            "Найкращі":"Лучшие",
            "На ринку нерухомості Одеси":"На рынке недвижимости Одессы",
            "Усі пропозиції":"Все предложения",
            "Продаж та купівля нерухомості з АН Домінанта - це спосіб швидко і просто здійснити угоду":"Продажа и покупка недвижимости с АН Доминанта — это способ быстро и просто совершить сделку",
            "Ріелтор агентства зробить всю роботу за вас і супроводжує на кожному етапі, аж до завершального. Тільки так можна отримати задоволеного клієнта":"Риелтор агентства сделает всю работу за вас и сопроводит на каждом этапе, вплоть до завершающего. Только так можно получить довольного клиента",
            "Докладніше про нас":"Подробнее о нас",
            "фахівців":"специалистов",
            "з нерухомості":"по недвижимости",
            "об'єктів у базі":"объектов в базе",
            "новобудов у базі":"новостроек в базе",
            "угод за рік":"сделок за год",
            "Ми розмістимо ваше оголошення на популярних міських платформах для пошуку нерухомості. Наш фахівець з нерухомості організує особисті або онлайн покази вашого об'єкта для потенційних покупців.":"Мы разместим ваше объявление на популярных городских платформах для поиска недвижимости. Наш специалист по недвижимости организует личные или онлайн показы вашего объекта для потенциальных покупателей.",
            "11 філій по всьому місту, професійні співробітники, які в найкоротші терміни підберуть Вам найкраще вирішення квартирного питання":"11 филиалов по всему городу, самые профессиональные сотрудники, которые в кратчайшие сроки подберут Вам лучшее решение квартирного вопроса",
            "Отримати консультацію":"Получить консультацию",
            "Як відбувається співпраця":"Как происходит сотрудничество",
            "Заявка":"Заявка",
            "Ми цінуємо кожного нашого клієнта та прагнемо надати лише найвищий рівень сервісу. Якість та професіоналізм – це основні принципи, якими ми керуємося в роботі. Тому, при зверненні до нас, ви можете бути впевнені, що вам буде надано досвідченого експерта, який спеціалізується саме на вашому типі нерухомості":"Мы ценим каждого нашего клиента и стремимся предоставить только самый высокий уровень сервиса. Качество и профессионализм - это основные принципы, которыми мы руководствуемся в работе. Поэтому, при обращении к нам, вы можете быть уверены, что вам будет предоставлен опытный эксперт, который специализируется именно на вашем типе недвижимости",
            "Консультація":"Консультация",
            "Завдяки унікальній базі об'єктів та передовим цифровим технологіям. Ми надаємо клієнтам найбільш релевантну добірку об'єктів, які найкраще відповідають їхнім запитам та потребам":"Благодаря уникальной базе объектов и передовым цифровым технологиям. Мы предоставляем клиентам наиболее релевантную подборку объектов, которые наилучшим образом соответствуют их запросам и потребностям",
            "Огляд":"Осмотр",
            "Ми цінуємо ваш час та комфорт, тому зробимо все можливе, щоб ваше знайомство з нерухомістю було приємним та результативним. Наші тури – це незабутній досвід, який дозволить вам поринути у атмосферу обраного району. Наш агент покаже вам всі цікаві місця, розповість про нерухомість, її історію та архітектуру. Ви дізнаєтеся про інфраструктуру району та інші особливості, які допоможуть вам зробити правильний вибір":"Мы ценим ваше время и комфорт, поэтому сделаем все возможное, чтобы ваше знакомство с недвижимостью было приятным и результативным. Наши туры - это незабываемый опыт, который позволит вам окунуться в атмосферу выбранного района. Наш агент покажет вам все интересные места, расскажет о недвижимости, ее истории и архитектуре. Вы узнаете об инфраструктуре района и других особенностях, которые помогут вам сделать правильный выбор",
            "Договір":"Договор",
            "Ми готові надати вам якісний супровід під час придбання нерухомості. Наші досвідчені юристи мають високу кваліфікацію та професійний підхід до роботи. Вони проведуть юридичне обстеження об'єкта нерухомості та оцінять усі юридичні та фінансові ризики, пов'язані з угодою":"Мы готовы предоставить вам качественное сопровождение при приобретении недвижимости. Наши опытные юристы обладают высокой квалификацией и профессиональным подходом к работе. Они проведут юридическое обследование объекта недвижимости и оценят все юридические и финансовые риски, связанные с сделкой",
            "Чому з Dominanta вигідно співпрацювати":"Почему с Dominanta выгодно сотрудничить",
            "Широкий спектр послуг":"Широкий спектр услуг",
            "Найкраща база об'єктів":"Лучшая база объектов",
            "Більш ніж 10-річний досвід":"Более чем 10-летний опыт",
            "Індивідуальний підхід до кожного":"Индивидуальный подход к каждому",
            "Новобудови без комісії":"Новострои без комиссии",
            "Домінанта офіційний партнер найкращих новобудов міста Одеса. Купуйте з нами та не платіть комісію":"Доминанта официальный партнер лучших новостроев города Одесса. Покупайте с нами и не платите комиссию",
            "Комісія":"Комиссия",
            "Послуги":"Услуги",
            "ХОЧУ СТАТИ ЧАСТИНОЮ КОМАНДИ":"ХОЧУ СТАТЬ ЧАСТЬЮ КОМАНДЫ",
            "Про нас":"О нас",
            "Dominanta - твій ключ":"Dominanta - твой ключ",
            "Вакансії":"Вакансии",
            "Наші проєкти":"Наши проекты",
            "Бонус":"Бонус",
            "Всі права захищені":"Все права защищены",
            "Завантажити":"Скачать",
            "Застосунок":"Приложение",
            "Вгору":"Вверх",
            "Головна":"Главная",
            "Каталог об'єктів":"Каталог объектов",
            "Одеса":"Одесса",
            "результатів":"результатов",
            "Дізнатись детальніше":"Узнать подробнее",
            "Слідкуйте за новими пропозиціями – підписуйтесь на наші канали.":"Следите за новыми предложениями — подписывайтесь на наши каналы.",
            "Фільтри":"Фильтры",
            "Не вдається самостійно знайти нерухомість?":"Не удается самостоятельно найти недвижимость?",
            "Дайте відповідь на пару простих питань":"Ответьте на пару простых вопросов ",
            "і наш агент зробить усю роботу за вас!":"и наш агент сделает всю работу за вас!",
            "Відповісти на питання":"Ответить на вопросы",
            "Ще фільтри":"Еще фильтры",
            "Район":"Район",
            "Пошук за ID":"Поиск по ID",
            "Приховати":"Скрыть",
            "Вартість":"Стоимость",
            "В продажі":"В продаже",
            "кімнати":"комнаты",
            "кімната":"комната",
            "поверх з":"этаж из",
            "Детальніше":"Подробнее",
            "Опис":"Описание",
            "Характеристики":"Характеристики",
            "Розташування":"Расположение",
            "Основні":"Основные",
            "Код об'єкта":"Код объекта",
            "Область":"Область",
            "Населений пункт":"Населенный пункт",
            "Про квартиру":"О квартире",
            "Про комерцію":"Про комерцию",
            "Кімнатність":"Комнатность",
            "Висота стелі":"Высота потолка",
            "Санвузол":"Санузел",
            "Балкон":"Балкон",
            "Стан балкону":"Состояние балкона",
            "Вікна":"Окна",
            "Сторони світу":"Стороны света",
            "Вид":"Вид",
            "Тераса":"Тераса",
            "Про будинок":"О доме",
            "Матеріал будівництва":"Материал постройки",
            "Двір":"Двор",
            "Адреса":"Адрес",
            "Ріелтор об'єкту":"Риелтор объекта",
            "Записати на перегляд":"Записать на просмотр",
            "Схожі об'єкти":"Похожие объекты",
            "Кільк. кімнат":"Кол. комнат",
            "Замовити дзвінок":"Заказать звонок",
            "Ваше ім'я": "Ваше имя",
            "Ознайомлений з": "Ознакомлен с",
            "Політикою конфіденційності": "Политикой конфиденциальности",
            "та даю згоду на збір та обробку своїх персональних даних": "и даю согласие на сбор и обработку своих персональных данных",
            "Дякуємо":"Спасибо",
            "Ми зв'яжемося з вами найближчим часом":"Мы свяжемся с вами в ближайшее время",
            "Партнери, якими ми пишаемось":"Партнеры, которыми мы гордимся",
            "96% клієнтів задоволені нашими послугами":"96% клиентов довольны нашими услугами",
            "З компанією співпрацюють понад 300 експертів у сфері нерухомості, які безперервно разом із Dominanta, проходять навчання для надання якісних послуг. А після угоди – клієнти оцінюють їхню роботу":'С компанией сотрудничают более 300 экспертов в сфере недвижимости, которые непрерывно вместе с Dominanta, проходят обучение для предоставление качественных услуг.А после сделки - клиенты оценивают их работу',
            "Залишити відгук":'Оставить отзыв',

            "Спеціалізація":"Специализация",
            "Досвід роботи":"Опыт работы",
            "Сегмент":"Сегмент",
            "Дача":"Дача",
            "Кількість угод":"Количество сделок",
            "Новобуди та вторинний ринок":"Новостройки и вторичный рынок",
            "16 років":"16 лет",
            "Середній":"Средний",
            "Понад 200":"Свыше 200",
            "Вторинний ринок нерухомості":"Вторичный рынок недвижимости",
            "14 років":"14 лет",
            "Вищий за середній":"Выше среднего",
            "Понад 500":"Свыше 500",
            "Новобудова та вторинний ринок, комерція":"Новостройка и вторичный рынок, коммерция",
            "8 років":"8 лет",
            "Елітна нерухомість":"Элитная недвижимость",
            "Понад 450":"Более 450",
            "Ріелторські послуги при купівлі та продажу":"Риэлторские услуги при покупке и продаже",
            "Відгуки":"Отзывы",
            "Мої об'єкти":"Мои объекты",
            "Залишіть Ваш номер телефону та Вам передзвонять протягом 5 хвилин":"Оставьте Ваш номер телефона и вам перезвонят в течении 5 минут",
            "Ваш відгук":'Ваш отзыв',
            "Надіслати": "Отправить",
            "ЖК Кімолос": "ЖК Кимолос",
            "18-поверховий проєкт бізнес класу в приморському районі з панорамним видом на море, парки та пляжі Аркадії": "18-этажный проект бизнес класса в приморском районе с панорамным видом на море, парки и пляжи Аркадии",
            "ЖК Breeze Marine": "ЖК Breeze Marine",
            "Для короткострокової або довгострокової оренди. Проєкт апарт-комплексів на узбережжі Чорного моря. Басейн і зона відпочинку на даху":"Для краткосрочной или долгосрочной аренды. Проект апарт-комплексов на побережье Черного моря. Бассейн и зона отдыха на крыше",
            "ЖК Modern":"ЖК Modern",
            "Багатосекційний проєкт на 6 станції Фонтана. Нестандартний дизайн фасадів, внутрішній простір для мешканців, просторе лобі та консьєрж-сервіс":"Многосекционный проект на 6 станции Фонтана. Нестандартный дизайн фасадов, внутреннее пространство для жильцов, просторное лобби и консьерж-сервис",
            "Так":"Єсть",
            "Залиште свій номер телефону, і наш фахівець зв'яжеться з вами. Він поставить кілька запитань про вашу нерухомість і розмістить ваше оголошення на сайті":"Оставьте свой номер телефона, и наш специалист свяжется с вами. Он задаст несколько вопросов о вашей недвижимости и разместит ваше объявление на сайте",
            "Чому вибирають нас":"Почему выбирают нас",
            "Гарантії та переваги роботи з нашим агенством":"Гарантии и преимущества сотрудничества с нашим агенством",
            "Тип санвузла":"Тип санузла",
            "Тип вікон":"Тип окон",
            "Каталог будинків":"Каталог домов",
            "Таунхауз":"Таунхаус",
            "Форма ділянки":"Форма участка",
            "Про ділянку":"Об участке",
            "Площа ділянки":"Площадь участка",
            "Місце знаходження":"Место нахождения",
            "Комунікації":"Коммуникации",
            "сотки":"сотки",
            "Квартири":"Квартири",
            "Каталог ділянок":"Каталог участков",
            "ділянка":"участок",
            "Вибрані":"Избранное",
            "Вибрані об'єкти":"Выбранные объекты",
            "Ми відкриті":"Мы открыты",
            "Пн - Пт: 09:00-18:00":"Пн - Пт: 09:00-18:00",
            "Назва компанії":"Название компании",
            "Центральний офіс Пушкінська вулиця, 32":"Центральный офис Пушкинская улица, 32",
            "Філія 1 , Пушкінська 32":"Филия 1 , Пушкинская 32",
            "Філія 4, Французький бульвар 2":"Филиал 4, Французский бульвар 2",
            "Філія 5, Генуезька 24Б":"Филиал 5, Генуэзская 24Б",
            "Філія 12, Семена Палія, 103":"Филиал 12, Семена Палия, 103",
            "Філія 14, Пушкінська 32":"Филиал 14,Пушкинская 32",
            "Філія 17, Б. Арнаутська 23б":"Филиал 17, Б.Арнаутская 23б",
            "Призначення":"Назначение",
            "Занадто багато запитів спробуйте пізніше":"Занадто багато запитів спробуйте пізніше",
            "Щось пішло не так":"Щось пішло не так",
            "Ні":"Ні",


        }
    }
};

i18n
    .use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        // lng: "uk_UA", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // // if you're using a language detector, do not define the lng option
        // fallbackLng: 'uk_UA',
        // defaultLocale: 'uk_UA',
        // lng: "uk", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option
        fallbackLng: 'uk',
        defaultLocale: 'uk',
        // retryInDefaultLocale: false,
        // cookie: 'dominantaLang',
        debug: true,
        ssr: false,

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;