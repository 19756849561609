import React from "react";
import PropTypes from "prop-types";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { withStyles } from "@material-ui/core/styles";

const CustomAutocomplete = withStyles((theme)=> ({
  root: {
    "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
      width: "3px", // Set desired width
    },
    "& .MuiFilledInput-root .MuiFilledInput-input": {
      // padding: "0 8px", // Set the desired padding
      //   "padding-bottom": "0px",
      "width": "3px",
    },
    "& .MuiInputBase-input.MuiFilledInput-input.MuiInputBase-inputAdornedEnd.MuiAutocomplete-input.MuiAutocomplete-inputFocused.css-1gctnaj-MuiInputBase-input-MuiFilledInput-input": {
      userSelect: "none", // Prevent text selection
    },
    "& .MuiInputBase-input": {
      userSelect: "none", // Prevent text selection
    },

    [theme.breakpoints.down("sm")]: {
      "&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiFilledInput-root": {
        paddingTop: "9px", // Mobile-specific padding (top-bottom, left-right)
      },
      "& .MuiFormLabel-root.MuiInputLabel-root": {
        top: "-4px", // Add desired padding
      }
    },

  },



  tag: {
    height: 22,
    position: "relative",
    zIndex: 0,
    "& .MuiChip-label": {
      color: "#fff",
    },
    "& .MuiChip-deleteIcon": {
      // color: "red"
    },
    "&:after": {
      content: '""',
      // right: 170,
      // top: 6,
      // height: 12,
      // width: 12,
      position: "absolute",
      backgroundColor: "white",
      zIndex: -1,
    },
  },
}))(Autocomplete);
const SelectItems = ({
                       classes,
  items,
  label,
  multiple,
  callbackChange,
  selected,
  limitTags = 2,

}) => {



  return (
    <div className="filter-fields-group__cell-input">
      <CustomAutocomplete
        // id='rooms-count'
        options={items}
        value={selected}
        multiple={multiple}
        limitTags={limitTags}
        onChange={callbackChange}
        renderInput={(params) => (
          <TextField {...params} label={label} variant="filled" />
        )}
      />
    </div>
  );
};

SelectItems.propTypes = {};

export default SelectItems;
