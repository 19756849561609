import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import SimilarItem from "./SimilarItem";
import SimilarItemMobile from "./SimilarItemMobile";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useWindowWidth } from "../../../hooks/useWindowWidth";

const SimilarSection = ({ type, data = [] }) => {
  const windowWidth = useWindowWidth();
  const [isMobile, setIsMobile] = useState(windowWidth < 768);
  const { t, i18n } = useTranslation();
  const [settings] = useState({
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2.2,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
        },
      },
    ],
  });
  useEffect(() => {
    setIsMobile(windowWidth < 768);
  }, [windowWidth]);

  return (
    <div className="similar-objects">
      <div className="container">
        <div className="home-page--best-deals__title">
          <div className="cell">
            <h2>{t("Схожі об'єкти")}</h2>
            <p>{t("На ринку нерухомості Одеси")}</p>
          </div>

          <div className="cell">
            <Link to={`/${type}`} className="all-deals">
              {" "}
              <button className="button-all-deals">{t("Усі пропозиції")}</button>
            </Link>
          </div>
        </div>
        <div className="home-page--best-deals-tabs">
          <div className="home-page--best-deals-tabs__head"></div>
          <div className="home-page--best-deals-tabs__content">
            <div className="home-page--best-deals-tab">
              {!isMobile ? (
                <div className="home-page--best-deals-list">
                  {data &&
                    data.map((obj, index) => {
                      return <SimilarItem key={index} item={obj} type={type} />;
                    })}
                </div>
              ) : (
                <div className="home-page--best-deals-mobile-list">
                  {data && data.length > 0 && (
                    <Slider {...settings} key={"mob_deals"}>
                      {data &&
                        data.map((obj, index) => {
                          return (
                            <SimilarItemMobile
                              key={index}
                              item={obj}
                              type={type}
                            />
                          );
                        })}
                    </Slider>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimilarSection;
