import React, {createContext, useEffect, useReducer, useRef} from 'react'
import {
  clearNavigationState,
  setActiveScrollPosition,
  setApartmentResultsOpen
} from "../store/reducers/navigationState";
import {debounce, scrollTopAction} from "../utils/common";
import {useDispatch, useSelector} from "react-redux";

// Створюємо контекст
const MobileFilterNavigationContext = createContext()
const defaultValue = {

  isOpenMobileFilter:false,
  isOpenMobileResult:false,
  showResults:true,


}

// Створюємо редуктор
const initialState = {
  isOpenMobileFilter:false,
  isOpenMobileResult:false,
  showResults:true,
}

function reducer(state, action) {
  switch (action.type) {
    case 'resetMobileFilterNavigation':
      return { ...state, ...defaultValue }
    case 'openMobileFilterNavigation':
      return { ...state, ...{ isOpenMobileFilter: action.payload } }
    case 'openMobileResultNavigation':
      return { ...state, ...{ isOpenMobileResult: action.payload } }
    case 'changeShowResults':
      return { ...state, ...{ showResults: action.payload } }
    // case 'openAdvancedFilterMobile':
    //   return { ...state, ...{ showResults: action.payload } }

    default:
      throw new Error()
  }
}

const MobileFilterNavigationProvider = ({ children }) => {
  const refIsPaginated = useRef(false);
  const refShowAdvancedFilterMobile = useRef(false);
  const [stateMobileFilterNavigation, dispatchMobileFilterNavigation] = useReducer(reducer, initialState)
  const dispatch = useDispatch();
 // it's store
  const activeScrollPosition = useSelector((state) => state.navigationState.activeScrollPosition);
  const apartmentResultsOpen = useSelector((state) => state.navigationState.apartmentResultsOpen);




  function hasScroll(el) {
    const hasVerticalScroll = el.scrollHeight > el.clientHeight;
    const hasHorizontalScroll = el.scrollWidth > el.clientWidth;

    return {
      vertical: hasVerticalScroll,
      horizontal: hasHorizontalScroll
    };
  }
  ///////////////////////////////     working functions
  const changeNavigationState = (value) => {
    dispatch(setApartmentResultsOpen(value));
    //ховаемо або відображаемо результат пошуку
    dispatchMobileFilterNavigation({type: 'openMobileResultNavigation', payload:value})
  }
  const changeNavigationFilterState = (value) => {
    refShowAdvancedFilterMobile.current=value;
    dispatchMobileFilterNavigation({type: 'changeShowResults', payload:true})
    dispatchMobileFilterNavigation({type: 'openMobileResultNavigation', payload:false})
  }
  const callbackAfterClickBurgerMenu = (value) => {
    dispatch(clearNavigationState());
    dispatchMobileFilterNavigation({type: 'resetMobileFilterNavigation'})
    refShowAdvancedFilterMobile.current=false;
    refShowAdvancedFilterMobile.current = false
  }
  const callbackIsPaginated = () => {
    dispatch(setActiveScrollPosition(0))
    refIsPaginated.current = true
    scrollTopAction();
  }

  const handlerShowProposals = () => {

    refShowAdvancedFilterMobile.current=false;
    refIsPaginated.current=true;
    dispatchMobileFilterNavigation({type: 'changeShowResults', payload:true})
    dispatchMobileFilterNavigation({type: 'openMobileResultNavigation', payload:false})
    dispatch(setActiveScrollPosition(0))
    refShowAdvancedFilterMobile.current = false
    scrollTopAction();
  };

  const openAdvancedFilterMobile = () => {
    //ховаемо або відображаемо результат пошуку
    dispatchMobileFilterNavigation({type: 'changeShowResults', payload: !stateMobileFilterNavigation.showResults})

    // setShowAdvancedFilterMobile(!showAdvancedFilterMobile);
    refShowAdvancedFilterMobile.current = !refShowAdvancedFilterMobile.current
    dispatch(clearNavigationState());
    scrollTopAction();
  };


  ///////////////////////////////     working functions




  useEffect(() => {

    // open search results read data from redux store todo check apartment or plot or comerce ...
    if (apartmentResultsOpen){
      dispatchMobileFilterNavigation({type: 'openMobileResultNavigation', payload:true})
    }



    const elm = document.documentElement;



    const debouncedResizeCallback = debounce(() => {
      const elm = document.documentElement;
      const { vertical, horizontal } = hasScroll(elm);
      if (vertical){
        if (!refIsPaginated.current && !refShowAdvancedFilterMobile.current){
          elm.scrollTo({
            top: activeScrollPosition,
            behavior: "auto" // Immediate scrolling
          });
        }

      }
    }, 200); // Adjust delay (200ms in this case)
    const calculateScrollPercentage = () => {
      const elm = document.documentElement;
      const scrollTop = elm.scrollTop; // Pixels scrolled from the top
      // if (!showAdvancedFilterMobile){
      if (!refShowAdvancedFilterMobile.current){
        dispatch(setActiveScrollPosition(scrollTop))
      }else{
        dispatch(setActiveScrollPosition(0))
      }

    };

    const debouncedScrollHandler = debounce(calculateScrollPercentage, 200);


    // Create and observe using ResizeObserver
    const observer = new ResizeObserver(debouncedResizeCallback);

    observer.observe(elm);


    // window.addEventListener('popstate', handlePopState);
    window.addEventListener("scroll", debouncedScrollHandler);
    // elm.addEventListener("scroll", calculateScrollPercentage);
    return () => {
      observer.disconnect(); // Stops observing all elements
      window.removeEventListener("scroll",debouncedScrollHandler);
      // window.removeEventListener('popstate', handlePopState);
    }

  }, []);

  return <MobileFilterNavigationContext.Provider value={{ stateMobileFilterNavigation, dispatchMobileFilterNavigation,refIsPaginated,refShowAdvancedFilterMobile,changeNavigationState,changeNavigationFilterState,callbackAfterClickBurgerMenu,callbackIsPaginated,handlerShowProposals,openAdvancedFilterMobile }}>{children}</MobileFilterNavigationContext.Provider>
}

export { MobileFilterNavigationContext }
export default MobileFilterNavigationProvider
