import _ from "lodash";

export function scrollTopAction(behavior = "auto") {

  window.scrollTo({
    top: 0,
    left: 0,
    behavior: behavior,
  });
}
export   function debounce(func, delay) {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };
}
export function clearEmptyKeys(obj) {
  return _.omitBy(obj, value => {
    // Check for "empty" values
    return (
        value === null ||
        value === undefined ||
        value === "" ||
        (_.isArray(value) && _.isEmpty(value)) ||
        (_.isObject(value) && _.isEmpty(value))
    );
  });
}