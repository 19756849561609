import React, {useState, useEffect, useRef, useContext} from "react";
import Logo from "/src/components/AppHeader/components/Logo";
import AppHeaderMenu from "/src/components/AppHeader/components/AppHeaderMenu";
import AppHeaderActions from "/src/components/AppHeader/components/AppHeaderActions";
import { useTranslation } from "react-i18next";
import {useHistory, useLocation} from "react-router-dom";
import _ from "lodash";
import clsx from "clsx";
import {
  MobileFilterApartmentIcon,
  MobileFilterCommercesIcon,
  MobileFilterHousesIcon,
  MobileFilterPlotsIcon,
  MobilePlaceIcon,
  MobileSelectedApartmentRoundIcon,
  MobileSelectedCommercesRoundIcon,
  MobileSelectedFavoriteRoundIcon,
  MobileSelectedHousesRoundIcon,
  MobileSelectedPlotsRoundIcon,
  MobileSettingFilterIcon,
} from "../vector/MobileFilterIcons";
import AppNetworks from "./components/AppNetworks";
// import moveToPopulars from "lodash";
import {setPopulars, setPurposes, setResetSelectedFilter} from "../../store/reducers/selectedFilterData";
import {useDispatch} from "react-redux";
import useSearchMove from "../../hooks/useSearchMove";
import {PointIconNavigation} from "../vector/RoomIcons";
import {clearNavigationState} from "../../store/reducers/navigationState";
import {MobileFilterNavigationContext} from "../../providers/MobileFilterNavigationProvider";
import PriceSelect from "../CatalogFiltersComponents/PriceSelect";

const menuOptions = [
  {
    title: "Квартири вторинний ринок",
    icon: <MobileFilterApartmentIcon />,
    iconSelectedRound: <MobileSelectedApartmentRoundIcon />,
    value: "catalog",
    url: "catalog",
  },
  {
    title: "Ділянки",
    icon: <MobileFilterPlotsIcon />,
    iconSelectedRound: <MobileSelectedPlotsRoundIcon />,
    value: "plots",
    url: "plots",
  },
  {
    title: "Будинки",
    icon: <MobileFilterHousesIcon />,
    iconSelectedRound: <MobileSelectedHousesRoundIcon />,
    value: "house",
    url: "house",
  },
  /*{
    title: "Новострой",
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 5.55556L10 2L17 5.55556V14.4444L10 18L3 14.4444V5.55556Z"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 11L10 9L14 11V16L10 18L6 16V11Z"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    value: 4,
  },*/
  {
    title: "Комерція",
    icon: <MobileFilterCommercesIcon />,
    iconSelectedRound: <MobileSelectedCommercesRoundIcon />,
    value: "commerce",
    url: "commerce",
  },
  {
    title: "Вибрані",
    icon: <MobileFilterCommercesIcon />,
    iconSelectedRound: <MobileSelectedFavoriteRoundIcon />,
    value: "favorites",
    url: "favorites",
    noUse: true,
  },
];

const menuOptionsMobileBurger = [
  {
    title: "Квартири вторинний ринок",
    icon:  <PointIconNavigation />,
    value: "catalog",
    url: "catalog",
  },
  {
    title: "Комерція",
    icon: <PointIconNavigation />,
    value: "commerce",
    url: "commerce",
  },
  {
    title: "Будинки",
    icon: <PointIconNavigation />,
    value: "house",
    url: "house",
  },
  {
    title: "Ділянки",
    icon: <PointIconNavigation />,
    value: "plots",
    url: "plots",
  },
  {
    title: "Продати",
    icon: <PointIconNavigation />,
    value: "sell",
    url: "sell",
  },
  {
    title: "Про компанію",
    icon: <PointIconNavigation />,
    value: "about-us",
    url: "about-us",
  },
  {
    title: "Контакти",
    icon: <PointIconNavigation />,
    value: "contacts",
    url: "contacts",
  },
]



const AppHeader = ({
  showMobileFilter,
  showCountRooms = true,
  setShowFilterMobile = (f) => f,
  useSettingButton = true,
  isActiveMobileIcon = false,
  callbackAfterClick = f=>f,
}) => {
  const [toggleMobileMenu, setToggleMobileMenu] = useState(false);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const search = useSearchMove("catalog");
  const commerceSearch = useSearchMove("commerce");
  const houseSearch = useSearchMove("house");
  const plotsSearch = useSearchMove("plots");
  const [locationName,setLocationName] = useState("");
  const history = useHistory();


  function moveToPage(slug) {
    dispatch(setResetSelectedFilter());
    // const overriddenData = { populars: slug };
    const overriddenData = { };
    search.moveToPage(slug);
  }

  const location = useLocation()
  useEffect(()=>{
    const element = document.getElementById("root");
      if (toggleMobileMenu) {
        if (element) {
          element.classList.add("mobile-menu-open");
        }
      }else {
        if (element) {
          element.classList.remove("mobile-menu-open");
        }
      }
  },[toggleMobileMenu])

  useEffect(()=>{
    setLocationName(location.pathname.split('/')[1])
  },[location])


  return (
      <>
        <header className="app-header">
          <div className="app-header--container">
            <Logo />

            <AppHeaderMenu />

            <AppNetworks />

            <AppHeaderActions />

            <button className="mob-menu--togle" onClick={()=>{
              setToggleMobileMenu(!toggleMobileMenu);
            }}>
              <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 12H18V10H0V12ZM0 7H18V5H0V7ZM0 0V2H18V0H0Z" fill="#7E7E7E"/></svg>
            </button>
          </div>

          <MobileFilterInHeader
              show={showMobileFilter}
              setShowFilterMobile={setShowFilterMobile}
              showCountRooms={showCountRooms}
              useSettingButton={useSettingButton}
              isActiveMobileIcon={isActiveMobileIcon}
          />

          <div className="mobile-navigation--layer">
            {toggleMobileMenu && <div className="mobile-navigation" onClick={()=>{
              setToggleMobileMenu(!toggleMobileMenu);
            }}>
              <div className="wrap-nav">
                <nav className="navbar">
                  <ul className="nav-links" id="navLinks">

                    {menuOptionsMobileBurger.map(e=>{

                      return  <li key={e.value} className={ clsx({active: locationName === e.url}) }  onClick={()=>{
                        // setToggleMobileMenu(false)
                        // history.push(`/${e.url}`)
                        callbackAfterClick()
                        // dispatch(clearNavigationState());
                        setToggleMobileMenu(false)
                        moveToPage(e.url);
                      }}>
                        <div  className="why-you--cell">
                          {e.icon}
                          <div className="why-you--cell_title"> {t(e.title)}</div>
                        </div>

                      </li>
                    })}

                  </ul>
                </nav>
              </div>
            </div>}
          </div>
        </header>

      </>

  );
};

const MobileFilterInHeader = ({
  show,
  showCountRooms = true,
  setShowFilterMobile = (f) => f,
  useSettingButton = true,
  isActiveMobileIcon=false
}) => {
  const [isActive, setIsActive] = useState(false);
  const [activeSelectedIcon, setActiveSelectedIcon] = useState(
    <MobileSelectedApartmentRoundIcon />,
  );
  const [activeComponent, setActiveComponent] = useState(false);
  const { t, i18n } = useTranslation();
  const setSowAction = () => {
    setIsActive(!isActive);
    setShowFilterMobile();
  };

  useEffect(() => {
    const pathArray = window.location.pathname.split("/").slice(1);
    const urlPath = _.first(pathArray);
    if (urlPath) {
      const selectedOption = menuOptions.find(
        (option) => option.url === urlPath,
      );
      if (selectedOption) {
        if (!selectedOption.noUse) {
          setActiveComponent(selectedOption);
        }
        setActiveSelectedIcon(selectedOption.iconSelectedRound);
      }
    }
  }, []);

  if (!show) return null;

  return (
    <div className="desctop-hidden">
      <div className="app-header--filter">
        <div className="dropdowns-cell">
          <DropDownWrap icon={activeSelectedIcon}>
            <div className="advanced-filter--content_form zdc">
              <div className="options-list">
                {menuOptions.map((obj) => {
                  if (!obj.noUse) {
                    return (
                      <Option
                        key={obj.value}
                        option={obj}
                        activeItem={activeComponent}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
          </DropDownWrap>

          <DropDownWrap icon={<MobilePlaceIcon />}>
            {
              //...
            }
          </DropDownWrap>
        </div>

        <div className="inputs-cell">
          {/*{showCountRooms && (*/}
          {/*  <div className="input-wrap">*/}
          {/*    <div className="selected-options">{t("Кільк. кімнат")}</div>*/}
          {/*  </div>*/}
          {/*)}*/}

          <div className="input-wrap">
            {/*<div className="selected-options">{t("Ціна")}</div>*/}
            <div className="filter-fields-group__cell">
              <PriceSelect type={"catalog"} />
            </div>

          </div>
        </div>

        <div className="filter-cell">
          {useSettingButton && (
            <button
              onClick={setSowAction}
              className={
                isActiveMobileIcon ? "show-filter--toggle active qxrr" : "show-filter--toggle"
              }
            >
              <MobileSettingFilterIcon />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const DropDownWrap = ({ icon, children }) => {
  const [open, setOpen] = useState(false);
  const dropRef = useRef(null);
  const { t, i18n } = useTranslation();
  const toggleAction = () => {
    //...
    setOpen(!open);
  };

  useEffect(() => {
    const handleClick = (event) => {
      if (dropRef.current && !dropRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [dropRef]);

  return (
    <div ref={dropRef} className="dropdown-wrap">
      <button onClick={toggleAction} className="toggle-action">
        {icon}
      </button>

      {open && (
        <div className="dropdown">
          {children}
          <button
            onClick={setOpen.bind(null, false)}
            className="dropdown-close"
          >
            {" "}
            {t("Приховати")}
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.31299 6.31253L17.6882 17.6877"
                stroke="#242328"
                strokeLinejoin="round"
              />
              <path
                d="M6.31299 17.6875L17.6882 6.3123"
                stroke="#242328"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      )}
    </div>
  );
};

const Option = ({ option = {}, activeItem }) => {
  const history = useHistory();

  const { t } = useTranslation();

  const handleClick = () => {
    history.push(`/${option.url}`);
  };

  return (
    <li
      className={clsx("option", { active: activeItem.value === option.value })}
      onClick={handleClick}
    >
      <span className="option-icon">{option.icon}</span>
      {t(option.title)}
      <button>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.50024 4.5L11.5004 11.5001"
            stroke="white"
            strokeLinejoin="round"
          />
          <path
            d="M4.50024 11.5L11.5004 4.49988"
            stroke="white"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </li>
  );
};
export default AppHeader;
