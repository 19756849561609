import React, { useEffect, useState } from "react";
import SelectItems from "../ui/selects/SelectItems";
import { useDispatch, useSelector } from "react-redux";
import { setDistrict, setRooms } from "../../store/reducers/selectedFilterData";
import useSearchMove from "../../hooks/useSearchMove";
import { useTranslation } from "react-i18next";
import {useLocation} from "react-router-dom";
import {useQuery} from "../../hooks/useQuery";

const DistrictItemsSelect = ({ type }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const items = useSelector((state) => state.filter.value.districts);
  const [selectedItems, setSelectedItems] = useState(null);

  const location = useLocation();
  const query = useQuery();

  const search = useSearchMove(type);
  const handlerSelectRoomsChange = (event, newValue) => {
    dispatch(setDistrict(newValue?.id ?? ""));
    let overriddenData = { district: newValue?.id ?? "" };
    search.moveToFilterOverridden(overriddenData);
  };


  useEffect(()=>{

    const param = query.get('district');
    if (items.length && param) {
      let filtered = items.find((e) => e.slug === param);
      if(!filtered) {
        filtered= null
      }
      setSelectedItems(filtered);
    }
  },[location])

  useEffect(() => {

    if (items.length) {
      const param = query.get('district');
      const filtered = items.find(
          (e) => e.id === parseInt(param),
      );
      setSelectedItems(filtered);
    }
  }, [items]);


  return (
    <React.Fragment>
      <SelectItems
        items={items}
        selected={selectedItems}
        label={t("Район")}
        multiple={false}
        callbackChange={handlerSelectRoomsChange}
      />
    </React.Fragment>
  );
};

DistrictItemsSelect.propTypes = {};

export default DistrictItemsSelect;
