import React, { useState, useEffect } from "react";
import AddToFavorites from "../ui/AddToFavorites";
import Slider from "react-slick";
import {Link, useHistory} from "react-router-dom";
import { apiGet } from "/src/utils/api";
import { useDispatch, useSelector } from "react-redux";
import { putBestProposalsObjects } from "../../store/reducers/bestProposals";
import { useTranslation } from "react-i18next";

const paramName="commerce";
const CommerceBestDeals = ({ mobile = false, settings = {} }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const items = useSelector((state) => state.bestProposals.data);
  useEffect(() => {
    apiGet("rooms", { type: paramName })
      .then((data) => data.data)
      .then((response) => {
        // setItems(response.data)
        dispatch(putBestProposalsObjects(response.data));
      });
    return () => {
      dispatch(putBestProposalsObjects([]));
    };
  }, [i18n.language]);
  return (
    <div className="home-page--best-deals-tab">
      {!mobile ? (
        <div className="home-page--best-deals-list">
          {items &&
            items.map((obj, index) => {
              return <CommerceItem key={index} item={obj} />;
            })}
        </div>
      ) : (
        <div className="home-page--best-deals-mobile-list">
          {items.length > 0 && (
            <Slider {...settings} key={"mob_deals"}>
              {items &&
                items.map((obj, index) => {
                  return <CommerceItemMobile key={index} item={obj} />;
                })}
            </Slider>
          )}
        </div>
      )}
    </div>
  );
};

const CommerceItem = ({ item = {} }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const moveTo = (event) => {
    const moveNext=['like-cell',"home-page--best-deals-list--item_thumbnail-mask"];
    if(moveNext.includes(event.target.className)){
      history.push(`/${paramName}/${item.slug}`)
    }
  }
  return (
    <div className="home-page--best-deals-list--item">
      <div className="home-page--best-deals-list--item_thumbnail" onClick={moveTo}>
        <img src={item.image.mini} alt="deals title" />

        <div className="home-page--best-deals-list--item_thumbnail-mask">
          <div className="like-cell">
            <AddToFavorites id={item.id} />
          </div>

          <div className="info-cell a4">
            <div className="price">
              {item.price_for_meter} $/м²
              <div className="total">{item.price} $</div>
            </div>

            <div className="id-label">ID {item.id}</div>
          </div>
        </div>
      </div>

      <div className="home-page--best-deals-list--item_details">
        <Link to={`/${paramName}/${item.slug}`}>
          <h3>{item.title}</h3>
        </Link>

        <div className="location">
          {item.district_name}, {item.city_name}
        </div>

        <ul className="details-list">
          {/*<li className="details-list--item"> <span>{item.cnt_room} {t("кімнати")}</span></li>*/}

          <li className="details-list--item">
            <span>{item.total_area} м2</span>
          </li>

          <li className="details-list--item">
            <span>
              {" "}
              {item.floor} {t("поверх")}
            </span>
          </li>

          {item.building_type_name && (
            <li className="details-list--item">
              <span>{item.building_type_name}</span>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

const CommerceItemMobile = ({ item = {} }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const moveTo = (event) => {
    const moveNext=['like-cell',"home-page--best-deals-list--item_thumbnail-mask"];
    if(moveNext.includes(event.target.className)){
      history.push(`/${paramName}/${item.slug}`)
    }
  }
  return (
    <div className="home-page--best-deals-mobile-list--item">
      <div className="home-page--best-deals-list--item">
        <div className="home-page--best-deals-list--item_thumbnail" onClick={moveTo}>
          <img src={item.image.mini} alt="deals title" />

          <div className="home-page--best-deals-list--item_thumbnail-mask">
            <div className="like-cell">
              <AddToFavorites id={item.id} />
            </div>

            <div className="info-cell a5">
              <div className="price">
                {item.price_for_meter} $/м²
                <div className="total">{item.price} $</div>
              </div>

              <div className="id-label">ID {item.id}</div>
            </div>
          </div>
        </div>

        <div className="home-page--best-deals-list--item_details">
          <Link to={`/${paramName}/${item.slug}`}>
            <h3>{item.title}</h3>
          </Link>

          <div className="location">
            {item.district_name}, {item.city_name}
          </div>

          <ul className="details-list">
            {/*<li className="details-list--item"><span> {item.cnt_room} {t("кімнати")}</span></li>*/}

            <li className="details-list--item">
              <span> {item.total_area} м2</span>
            </li>

            <li className="details-list--item">
              <span>
                {" "}
                {item.floor} {t("поверх з")} {item.max_floor}
              </span>
            </li>

            {item.building_type_name && (
              <li className="details-list--item">
                <span> {item.building_type_name}</span>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};
export default CommerceBestDeals;
