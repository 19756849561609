import axios from "axios";
import APP_CONFIG from "../config";
import i18n from "../i18n";

const headers = {};
export async function apiGet(path, params = {}) {
  const lang = i18n.language.slice(0, 2).toLowerCase();

  headers.lang = lang;


  let baseUrl=APP_CONFIG.apiBaseUrl;
  // if (typeof window === 'undefined'){
  //   baseUrl='http://nginx/'
  // }
  // return axios.get(APP_CONFIG.apiBaseUrl + "api/" + path, {
  return axios.get(baseUrl + "api/" + path, {
    headers,
    params,
  });
}

export function addTypeObjectToQueryByPathName(location, type) {
  let search = location.search;
  if (location.search === "") {
    search = `?type=${type}`;
  } else {
    search = search + `&type=${type}`;
  }
  return search;
}
